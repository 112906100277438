import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatRoom, ConnectionState, DisconnectReason } from 'amazon-ivs-chat-messaging';
import { RootState } from '../../app/store';

export interface StreamChatMessage {
  message: string;
  messageId: string;
  receivedTime: string;
  count: number;
  username?: string;
  type?: string;
  isStreamerFollower?: boolean;
}

export enum MessageType {
  MESSAGE = 'MESSAGE',
  REACTION = 'REACTION',
  FOLLOW = 'FOLLOW',
}

export interface StressChatRoom {
  chatRoom: ChatRoom;
  roomArn: string;
}

export interface StressTestMessage {
  message: string;
  messageType: MessageType;
  chatRoomId: string;
  userName: string;
  showId: string;
}
export interface FollowEvent {
  chatRoomId: string;
  userName: string;
  streamerName: string;
  requestsPerSecond: number;
}

export interface StressTestRoomInitializationParams {
  stressChatRoomId: string;
  showId: string;
}

export interface StreamChatState {
  chatRoom?: ChatRoom;
  state?: ConnectionState;
  disconnectReason?: DisconnectReason;
  messages: StreamChatMessage[];
  reactions: number;
  joinerUsername?: string;
  followerUsername?: string;
  stressTest: {
    chatRooms?: Record<string, StressChatRoom>;
  };
}

export const streamChatInitialState: StreamChatState = {
  chatRoom: undefined,
  state: undefined,
  disconnectReason: undefined,
  messages: [],
  reactions: 0,
  joinerUsername: undefined,
  followerUsername: undefined,
  stressTest: {
    chatRooms: undefined,
  },
};

export const getChatMessagesLive = createAction<string>('ivsChat/getChatMessagesLive');
export const deleteChatMessage = createAction<{ messageId: string; showId: string }>(
  'ivsChat/deleteChatMessage'
);
export const initializeChat = createAction<string>('ivsChat/initializeChat');
export const initializeStressTestChat = createAction<StressTestRoomInitializationParams>(
  'ivsChat/initializeStressTestChat'
);
export const sendMessage = createAction<StressTestMessage>('ivsChat/sendMessage');
export const closeTestChatRoomConnection = createAction<string>(
  'ivsChat/closeTestChatRoomConnection'
);
export const sendFollowEvent = createAction<FollowEvent>('ivsChat/sendFollowEvent');

export const streamChatSlice = createSlice({
  name: 'streamChat',
  initialState: streamChatInitialState,
  reducers: {
    setMessages(state, action: PayloadAction<StreamChatMessage[]>) {
      state.messages = state.messages.concat(action.payload);
    },
    clearMessages(state) {
      state.messages = [];
    },
    setJoinerUsername(state, action: PayloadAction<string | undefined>) {
      state.joinerUsername = action.payload;
    },
    setFollowerUsername(state, action: PayloadAction<string | undefined>) {
      state.followerUsername = action.payload;
    },
    setStressChatRoom(state, action: PayloadAction<Record<string, StressChatRoom>>) {
      state.stressTest.chatRooms = action.payload;
    },
    clearChat(state) {
      state.chatRoom = undefined;
      state.state = undefined;
      state.disconnectReason = undefined;
      state.messages = [];
      state.reactions = 0;
      state.joinerUsername = undefined;
      state.followerUsername = undefined;
    },
  },
});

export const {
  setMessages,
  setJoinerUsername,
  clearChat,
  setFollowerUsername,
  setStressChatRoom,
  clearMessages,
} = streamChatSlice.actions;

export default streamChatSlice.reducer;

export const streamChatSelector = (state: RootState) => state.streamChat;
export const streamChatSelectors = {
  chatRoom: createSelector(streamChatSelector, streamChat => streamChat.chatRoom),
  stressChatRooms: createSelector(
    streamChatSelector,
    streamChat => streamChat.stressTest.chatRooms
  ),
  state: createSelector(streamChatSelector, streamChat => streamChat.state),
  disconnectReason: createSelector(streamChatSelector, streamChat => streamChat.disconnectReason),
  messages: createSelector(streamChatSelector, streamChat => streamChat.messages),
  reactions: createSelector(streamChatSelector, streamChat => streamChat.reactions),
  joinerUsername: createSelector(streamChatSelector, streamChat => streamChat.joinerUsername),
  followerUsername: createSelector(streamChatSelector, streamChat => streamChat.followerUsername),
};
