import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {
  downloadCSV,
  extractGridTableColumnsToExportFromLocalStorage,
} from '../../../common/utils';
import { displayName } from '../../../creators/model/creator';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import { germanFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { EUROPE_BERLIN_TIMEZONE, START_SHOW_FORMAT } from '../../constants';
import { PastShowWithCreator, ShowTotalAndLivePerformance, StreamedBy } from '../../model/shows';

export function createShowsCsvData(
  shows: PastShowWithCreator[],
  getViewsFromPerformanceByAudienceAndSource: (performance: ShowTotalAndLivePerformance) => number,
  getViewersFromPerformanceByAudienceAndSource: (performance: ShowTotalAndLivePerformance) => number
) {
  return shows.map(show => {
    return {
      streamedBy: show.streamedBy === StreamedBy.HSE ? 'HSE' : 'Creator',
      title: show.title,
      startedAt: dayjs(show.startedAt).tz(EUROPE_BERLIN_TIMEZONE).format(START_SHOW_FORMAT),
      audience: show.audience.join('|'),
      streamId: show.id,
      streamerId: show.creator.id,
      creatorName: show.creator.name,
      creatorRole: displayName(show.creator.role),
      durationInSeconds: formatTime(show.durationInSeconds),
      streamFloorSaleLive: germanFormat(show.livePerformance.sales.streamTotal.amount),
      voucherFloorSaleTotal: germanFormat(
        show.totalPerformance.sales.voucherFloorSaleTotal?.amount ?? 0
      ),
      webFloorSalesLive: germanFormat(show.livePerformance.sales.webFloorSales.amount),
      appFloorSalesLive: germanFormat(show.livePerformance.sales.appFloorSaleTotal.amount),
      iosFloorSalesLive: germanFormat(show.livePerformance.sales.iosFloorSales.amount),
      androidFloorSalesLive: germanFormat(show.livePerformance.sales.androidFloorSales.amount),
      streamFloorSaleTotal: germanFormat(show.totalPerformance.sales.streamTotal.amount),
      webFloorSalesTotal: germanFormat(show.totalPerformance.sales.webFloorSales.amount),
      appFloorSalesTotal: germanFormat(show.totalPerformance.sales.appFloorSaleTotal.amount),
      iosFloorSalesTotal: germanFormat(show.totalPerformance.sales.iosFloorSales.amount),
      androidFloorSalesTotal: germanFormat(show.totalPerformance.sales.androidFloorSales.amount),
      streamOrdersLive: show.streamOrdersLive,
      webOrdersCountLive: show.livePerformance.ordersOverview.webOrdersCount,
      appOrdersCountLive:
        show.livePerformance.ordersOverview.androidOrdersCount +
        show.livePerformance.ordersOverview.iosOrdersCount,
      iosOrdersCountLive: show.livePerformance.ordersOverview.iosOrdersCount,
      androidOrdersCountLive: show.livePerformance.ordersOverview.androidOrdersCount,
      streamOrdersTotal: show.streamOrdersTotal,
      voucherOrdersTotal: show.totalPerformance.ordersOverview.voucherOrdersCount,
      webOrdersCountTotal: show.totalPerformance.ordersOverview.webOrdersCount,
      appOrdersCountTotal:
        show.totalPerformance.ordersOverview.androidOrdersCount +
        show.totalPerformance.ordersOverview.iosOrdersCount,
      iosOrdersCountTotal: show.totalPerformance.ordersOverview.iosOrdersCount,
      androidOrdersCountTotal: show.totalPerformance.ordersOverview.androidOrdersCount,
      streamConversionLive: germanFormat(show.conversionOverview.streamConversionLive * 100),
      conversionIosLive: germanFormat(show.conversionOverview.iosConversionLive * 100),
      conversionAndroidLive: germanFormat(show.conversionOverview.androidConversionLive * 100),
      conversionAppLive: germanFormat(show.conversionOverview.appConversionLive * 100),
      conversionWebLive: germanFormat(show.conversionOverview.webConversionLive * 100),
      streamConversionTotal: germanFormat(show.conversionOverview.streamConversionTotal * 100),
      conversionIosTotal: germanFormat(show.conversionOverview.iosConversionTotal * 100),
      conversionAndroidTotal: germanFormat(show.conversionOverview.androidConversionTotal * 100),
      conversionAppTotal: germanFormat(show.conversionOverview.appConversionTotal * 100),
      conversionWebTotal: germanFormat(show.conversionOverview.webConversionTotal * 100),
      voucherCode: show.voucherCode ? show.voucherCode : '-',
      floorSalePerViewerLive: germanFormat(show.livePerformance.sales.perVisitor.amount),
      floorSalePerViewerTotal: germanFormat(show.totalPerformance.sales.perVisitor.amount),
      floorSalePerMinuteLive: germanFormat(show.livePerformance.sales.perMinute.amount),
      floorSalePerMinuteTotal: germanFormat(show.totalPerformance.sales.perMinute.amount),
      viewsLive: getViewsFromPerformanceByAudienceAndSource(show.livePerformance),
      liveClassicViewsFromApp: show.livePerformance.viewsOverview.classicAppViews,
      liveClassicViewsFromWeb: show.livePerformance.viewsOverview.classicWebViews,
      liveClassicViewsFromIos: show.livePerformance.viewsOverview.classicIosViews,
      liveClassicViewsFromAndroid: show.livePerformance.viewsOverview.classicAndroidViews,
      viewsTotal: getViewsFromPerformanceByAudienceAndSource(show.totalPerformance),
      totalClassicViewsFromApp: show.totalPerformance.viewsOverview.classicAppViews,
      totalClassicViewsFromWeb: show.totalPerformance.viewsOverview.classicWebViews,
      totalClassicViewsFromIos: show.totalPerformance.viewsOverview.classicIosViews,
      totalClassicViewsFromAndroid: show.totalPerformance.viewsOverview.classicAndroidViews,
      viewersLive: getViewersFromPerformanceByAudienceAndSource(show.livePerformance),
      liveClassicViewersFromApp: show.livePerformance.viewersOverview.classicAppViewers,
      liveClassicViewersFromWeb: show.livePerformance.viewersOverview.classicWebViewers,
      liveClassicViewersFromIos: show.livePerformance.viewersOverview.classicIosViewers,
      liveClassicViewersFromAndroid: show.livePerformance.viewersOverview.classicAndroidViewers,
      viewersTotal: getViewersFromPerformanceByAudienceAndSource(show.totalPerformance),
      totalClassicViewersFromApp: show.totalPerformance.viewersOverview.classicAppViewers,
      totalClassicViewersFromWeb: show.totalPerformance.viewersOverview.classicWebViewers,
      totalClassicViewersFromIos: show.totalPerformance.viewersOverview.classicIosViewers,
      totalClassicViewersFromAndroid: show.totalPerformance.viewersOverview.classicAndroidViewers,
      timeWatchLive: formatTime(show.livePerformance.timeWatched?.seconds),
      timeWatchTotal: formatTime(show.totalPerformance.timeWatched?.seconds),
      timeWatchPerViewLive: formatTime(show.livePerformance.timeWatched?.perView),
      timeWatchPerViewTotal: formatTime(show.totalPerformance.timeWatched?.perView),
      timeWatchPerViewerLive: formatTime(show.livePerformance.timeWatched?.perViewer),
      timeWatchPerViewerTotal: formatTime(show.totalPerformance.timeWatched?.perViewer),
      sharesLive: show.livePerformance.shares,
      sharesTotal: show.totalPerformance.shares,
      likesLive: show.livePerformance.likes,
      likesTotal: show.totalPerformance.likes,
      chatMessagesLive: show.livePerformance.chatMessages,
      chatMessagesTotal: show.totalPerformance.chatMessages,
    };
  });
}

export function exportShowsCsv(
  shows: PastShowWithCreator[],
  columns: GridColDef[],
  getViewsFromPerformanceByAudienceAndSource: (performance: ShowTotalAndLivePerformance) => number,
  getViewersFromPerformanceByAudienceAndSource: (performance: ShowTotalAndLivePerformance) => number
) {
  const colsToExport = extractGridTableColumnsToExportFromLocalStorage(
    'columnVisibilityModel',
    columns
  );
  const data = createShowsCsvData(
    shows,
    getViewsFromPerformanceByAudienceAndSource,
    getViewersFromPerformanceByAudienceAndSource
  );
  downloadCSV(colsToExport, data, 'PastShows.csv');
}
