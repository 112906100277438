import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import {
  clearProductsSelection,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import theme from '../../../theme/theme.module.scss';
import { changeWhiteSpacesToHTMLFormat } from '../../../utils/formatters/htmlFormatter/htmlFormatter';
import CreateEditPostForm from '../../components/CreateEditPostForm/CreateEditPostForm';
import { StreamPostData } from '../../model/post';
import { clearPostCreationData, createNewPost } from '../../state/postsSlice';

const CreatePostView = () => {
  const dispatch = useDispatch();
  const { selectedProducts } = useSelector(selectProductSelection);

  useEffect(() => {
    return () => {
      dispatch(clearProductsSelection());
      dispatch(clearPostCreationData());
    };
  }, []);

  const onPublishPost = ({ medias, caption, scheduleAt, type }: StreamPostData) => {
    if (medias.length > 0 && caption?.trim()) {
      dispatch(
        createNewPost({
          medias,
          baseProductsNo: selectedProducts.map(p => p.baseProductNo),
          caption: changeWhiteSpacesToHTMLFormat(caption),
          scheduleAt,
          type,
        })
      );
    }
  };

  return <CreateEditPostForm isCreate submitCallback={onPublishPost} />;
};

function CreatePostPage() {
  return (
    <PageContainer
      backgroundColor={theme.lightgreyLightest}
      pageToRender={<CreatePostView />}
      fullHeight
    />
  );
}

export default CreatePostPage;
