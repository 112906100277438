import PageContainer from '../../../layout/PageContainer/PageContainer';
import CreatorPostsOverview from '../../../posts/components/CreatorPostsOverview/CreatorPostsOverview';
import theme from '../../../theme/theme.module.scss';

const CreatorInsightsPage = () => {
  return (
    <PageContainer
      pageToRender={<CreatorPostsOverview />}
      backgroundColor={theme.whiteLilac}
    ></PageContainer>
  );
};

export default CreatorInsightsPage;
