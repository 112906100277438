export enum DimensionType {
  COLOR = 'COLOR',
  SIZE = 'SIZE',
  ALLOY = 'ALLOY',
  TASTE = 'TASTE',
  MOTIV = 'MOTIV',
  AROMA = 'AROMA',
  AMOUNT = 'AMOUNT',
  STONE = 'STONE',
  OTHERS = 'OTHERS',
}

export interface ProductDimension {
  type: DimensionType;
  values: DimensionValue[];
}

export interface VariantDimension {
  type: DimensionType;
  value: string;
}

export interface BaseDimensionValue {
  value: string;
}

export interface ColorDimensionValue extends BaseDimensionValue {
  codeHex?: string;
  colorFamilies?: string[];
}

export type DimensionValue = ColorDimensionValue & BaseDimensionValue;

export type ProductSelectedDimensions = { [x in DimensionType]?: string };
