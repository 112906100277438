import { PayloadAction } from '@reduxjs/toolkit';
import { ChatRoom } from 'amazon-ivs-chat-messaging';
import { camelizeKeys } from 'humps';
import { call, put, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCustomerIdFromLocalStorage } from '../../common/utils';
import { ApiReturnType } from '../../shared/ApiReturnType';
import showsApi from '../../shows/api/showsApi';
import { FollowEventRequest } from '../../shows/api/showsRequestResponse';
import streamChatApi from '../api/streamChatApi';
import { ChatMessageResponse } from '../types';
import createChatChannel from './createChatChannel/createChatChannel';
import ivsChatUtils from './ivsChatUtils';
import {
  clearMessages,
  closeTestChatRoomConnection,
  deleteChatMessage,
  FollowEvent,
  getChatMessagesLive,
  initializeChat,
  initializeStressTestChat,
  sendFollowEvent,
  sendMessage,
  setFollowerUsername,
  setJoinerUsername,
  setMessages,
  setStressChatRoom,
  StreamChatMessage,
  streamChatSelectors,
  StressChatRoom,
  StressTestMessage,
  StressTestRoomInitializationParams,
} from './streamChatSlice';

export const mapChatMessageResponse = (
  chatMessageResponse: ChatMessageResponse
): StreamChatMessage => ({
  message: chatMessageResponse.content,
  messageId: chatMessageResponse.messageId,
  receivedTime: chatMessageResponse.receivedTime,
  count: chatMessageResponse.attributes?.count ? Number(chatMessageResponse.attributes.count) : 1,
  username: chatMessageResponse.attributes?.senderUsername,
  type: chatMessageResponse.attributes?.type,
  isStreamerFollower: chatMessageResponse.attributes?.isStreamerFollower === 'true',
});

export function* getChatMessagesLiveHandler(action: PayloadAction<string>) {
  try {
    const { messages }: ApiReturnType<typeof streamChatApi.getChatMessagesLive> = yield call(
      streamChatApi.getChatMessagesLive,
      action.payload
    );
    const liveMessages = [...(messages ?? [])]
      .reverse()
      .map(chatMessageResponse => mapChatMessageResponse(chatMessageResponse));
    yield put(setMessages(liveMessages));
  } catch (unknownError: unknown) {
    yield call(console.error, unknownError);
  }
}
function* setupChatEventListenersGenerator(chatRoom: ChatRoom): Generator {
  const eventChannel = createChatChannel(chatRoom, 'event');

  while (true) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chatEvent = (yield take(eventChannel)) as any;

    if (chatEvent.eventName === 'JOIN') {
      const username = !!chatEvent.attributes?.['sender_username']
        ? chatEvent.attributes['sender_username']
        : 'Gast';
      yield put(setJoinerUsername(username));
    }

    if (chatEvent.eventName === 'FOLLOW') {
      const username = !!chatEvent.attributes?.['sender_username']
        ? chatEvent.attributes['sender_username']
        : 'Gast';
      yield put(setFollowerUsername(username));
    }
  }
}

function* setupChatMessagesListenersGenerator(chatRoom: ChatRoom): Generator {
  const chatChannel = createChatChannel(chatRoom, 'message');

  while (true) {
    const payload = yield take(chatChannel);

    const chatMessage = mapChatMessageResponse(
      // eslint-disable-next-line
      camelizeKeys(payload as any) as unknown as ChatMessageResponse
    );
    yield put(setMessages([chatMessage]));
  }
}

export function* handleChatInitialization(action: PayloadAction<string>) {
  try {
    const showId = action.payload;
    const { roomArn }: ApiReturnType<typeof streamChatApi.getChatRoom> = yield call(
      streamChatApi.getChatRoom,
      showId
    );
    const customerId = getCustomerIdFromLocalStorage();
    if (customerId) {
      const chatRoom = ivsChatUtils.create(roomArn, customerId);
      ivsChatUtils.connect(chatRoom);
      yield spawn(setupChatEventListenersGenerator, chatRoom);
      yield spawn(setupChatMessagesListenersGenerator, chatRoom);
    }
  } catch (unknownError: unknown) {
    yield call(console.error, unknownError);
  }
}

export function* handleStressChatRoomInitialization(
  action: PayloadAction<StressTestRoomInitializationParams>
) {
  try {
    const showId = action.payload.showId;
    const { roomArn }: ApiReturnType<typeof streamChatApi.getChatRoom> = yield call(
      streamChatApi.getChatRoom,
      showId
    );
    const customerId = getCustomerIdFromLocalStorage();
    if (customerId) {
      const chatRoom = ivsChatUtils.create(roomArn, customerId);
      yield put(setStressChatRoom({ [action.payload.stressChatRoomId]: { chatRoom, roomArn } }));
      ivsChatUtils.connect(chatRoom);
    } else {
      yield call(console.error, 'the customer id is not set');
    }
  } catch (unknownError: unknown) {
    yield call(console.error, unknownError);
  }
}
export function* sendMessageHandler(action: PayloadAction<StressTestMessage>) {
  try {
    const stressChatRooms: Record<string, StressChatRoom> = yield select(
      streamChatSelectors.stressChatRooms
    );
    const StressChatRoom: StressChatRoom = stressChatRooms[action.payload.chatRoomId];
    const customerId = getCustomerIdFromLocalStorage();
    if (StressChatRoom && StressChatRoom.chatRoom.state === 'connected' && customerId) {
      yield StressChatRoom.chatRoom.sendMessage({
        action: 'SEND_MESSAGE',
        requestId: crypto.randomUUID(),
        content: action.payload.message.trim(),
        attributes: {
          type: action.payload.messageType,
          count: '1',
          sender_platform: 'web',
          show_id: action.payload.showId,
          sender_user_id: customerId,
          sender_username: action.payload.userName,
          is_streamer_follower: 'false',
        },
      });
    } else {
      yield call(console.error, 'chat room is not defined or customerId is not set');
    }
  } catch (error) {
    yield call(console.error, error as Error);
  }
}

export function* sendFollowEventHandler(action: PayloadAction<FollowEvent>) {
  try {
    const stressChatRooms: Record<string, StressChatRoom> = yield select(
      streamChatSelectors.stressChatRooms
    );
    const StressChatRoom: StressChatRoom = stressChatRooms[action.payload.chatRoomId];
    const customerId = getCustomerIdFromLocalStorage();
    if (!StressChatRoom || StressChatRoom.chatRoom.state !== 'connected' || !customerId) {
      yield call(console.error, 'chat room is not defined or customerId is not set');
      return;
    }
    const sendFollowEventRequest: FollowEventRequest = {
      followerUserName: action.payload.userName,
      streamerName: action.payload.streamerName,
      numberOfRequests: action.payload.requestsPerSecond,
      roomArn: StressChatRoom.roomArn,
    };
    yield call(showsApi.sendFollowEvent, sendFollowEventRequest);
  } catch (error) {
    yield call(console.error, error as Error);
  }
}

export function* closeTestChatRoomConnectionHandler(action: PayloadAction<string>) {
  try {
    const stressChatRooms: Record<string, StressChatRoom> = yield select(
      streamChatSelectors.stressChatRooms
    );
    const stressChatRoom: StressChatRoom = stressChatRooms[action.payload];
    if (!stressChatRoom) {
      yield call(console.error, 'chat room is not defined');
      return;
    }
    ivsChatUtils.disconnect(stressChatRoom.chatRoom);
  } catch (error) {
    yield call(console.error, error as Error);
  }
}
export function* deleteChatMessageHandler(
  action: PayloadAction<{ messageId: string; showId: string }>
) {
  try {
    const messageId = action.payload.messageId;
    const showId = action.payload.showId;
    yield call(streamChatApi.deleteMessage, messageId, showId);
    yield put(clearMessages());
    const newAction: PayloadAction<string> = {
      type: 'ivsChat/getChatMessagesLive',
      payload: showId,
    };

    yield call(getChatMessagesLiveHandler, newAction);
  } catch (error) {
    yield call(console.error, error as Error);
  }
}

export function* watcherStreamChatSagas() {
  yield takeLatest(getChatMessagesLive.type, getChatMessagesLiveHandler);
  yield takeLatest(deleteChatMessage.type, deleteChatMessageHandler);
  yield takeLatest(initializeChat.type, handleChatInitialization);
  yield takeEvery(initializeStressTestChat.type, handleStressChatRoomInitialization);
  yield takeEvery(sendMessage.type, sendMessageHandler);
  yield takeEvery(sendFollowEvent.type, sendFollowEventHandler);
  yield takeEvery(closeTestChatRoomConnection.type, closeTestChatRoomConnectionHandler);
}
