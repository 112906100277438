import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  username: Yup.string().required('Benutzername ist erforderlich'),
  actionType: Yup.string().required('Bitte wählen Sie eine Aktion aus'),
  requestPerSecond: Yup.number()
    .min(1, 'Minimalwert ist 1')
    .max(10, 'Maximalwert ist 10')
    .required('Bitte wählen Sie Anfragen pro Sekunde'),
});
