import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import { selectLoggedInCreator } from '../../../creators/state/creatorsSlice';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import {
  clearProductsSelection,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import Loading from '../../../shows/component/Loading/Loading';
import theme from '../../../theme/theme.module.scss';
import CreateEditPostForm from '../../components/CreateEditPostForm/CreateEditPostForm';
import { StreamPostData } from '../../model/post';
import {
  clearPostCreationData,
  fetchStreamerPost,
  selectPostIsBeingLoaded,
  selectStreamerPost,
  setIsInitialUpload,
  updatePost,
} from '../../state/postsSlice';
const UpdatePostView = () => {
  const dispatch = useDispatch();

  const { postId } = useParams<{ postId: string }>();
  const post = useSelector(selectStreamerPost);
  const loading = useSelector(selectPostIsBeingLoaded);
  const loggedInStreamer = useSelector(selectLoggedInCreator);
  const { selectedProducts } = useSelector(selectProductSelection);
  const isAdmin: boolean = useSelector(selectUserIsHSEEmployee);
  const onUpdatePost = ({ medias, caption, scheduleAt, type }: StreamPostData) => {
    if (caption?.trim()) {
      dispatch(
        updatePost({
          postId,
          request: {
            caption,
            medias,
            baseProductsNo: selectedProducts.map(p => p.baseProductNo),
            scheduleAt: scheduleAt,
            type,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setIsInitialUpload(false));
    dispatch(fetchStreamerPost(postId));
    return () => {
      dispatch(clearProductsSelection());
      dispatch(clearPostCreationData());
    };
  }, [postId]);

  if (loading) {
    return <Loading />;
  }

  if (!post) {
    return (
      <ErrorPageDialog errorDialogType={ErrorPageDialogType.ENTITY_NOT_FOUND} entityLabel="Post" />
    );
  }

  return (
    <CreateEditPostForm
      postToUpdate={post}
      isOwnPost={post.creator?.id === loggedInStreamer?.id || isAdmin}
      submitCallback={onUpdatePost}
    />
  );
};

function UpdatePostPage() {
  return (
    <PageContainer
      backgroundColor={theme.lightgreyLightest}
      pageToRender={<UpdatePostView />}
      fullHeight
    />
  );
}

export default UpdatePostPage;
