import { Box } from '@mui/material';
import cx from 'classnames';
import { ProductPrice } from '../../products/model/price';
import { germanMoneyFormat } from '../../utils/formatters/germanFormat/germanFormats';
import styles from './PriceInfo.module.scss';

interface Props {
  price: ProductPrice;
  className?: string;
  isColumn?: boolean;
  hideSavingPercentage?: boolean;
  useOriginalPriceReference?: boolean;
}

const PriceInfo = ({
  price,
  className,
  isColumn,
  hideSavingPercentage = false,
  useOriginalPriceReference = false,
}: Props) => {
  return (
    <div className={cx(className, styles.wrapper)}>
      <p className={styles.price}>{germanMoneyFormat(price.value, price.currencyCode)}</p>
      {price.reference && (
        <Box
          sx={
            isColumn
              ? { display: 'flex', flexDirection: 'column' }
              : { display: 'flex', flexDirection: 'row' }
          }
        >
          {!hideSavingPercentage && (
            <p className={styles.saving_percent}>
              -
              {useOriginalPriceReference && !!price.original
                ? price.original.savingPercent
                : price.reference.savingPercent}
              %
            </p>
          )}
          <p className={styles.reference_price}>
            {germanMoneyFormat(
              useOriginalPriceReference && !!price.original
                ? price.original.value
                : price.reference.value,
              price.currencyCode
            )}
          </p>
        </Box>
      )}
    </div>
  );
};

export default PriceInfo;
