import { Box } from '@mui/material';
import cx from 'classnames';
import React, { useState } from 'react';
import { ProductTileInfo } from '../../../model/productTileInfo';
import { mapProductSwatchToProductTitleInfo } from '../../../utils/productTileInfoMapper';
import { ProductSwatch } from '../../../utils/productTileInfoUtils';
import styles from './ProductColorPicker.module.scss';

interface ProductColorPickerProps {
  product: ProductTileInfo;
  setImagePreview: (imageUri: string) => void;
  isShown?: boolean;
  setSelectedProduct?: (product: ProductTileInfo) => void;
}

export const ProductColorPicker = ({
  product,
  setImagePreview,
  isShown,
  setSelectedProduct,
}: ProductColorPickerProps) => {
  if (!product.productSwatch || product.productSwatch.length === 1) {
    return null;
  }

  const hasProductSwatch = product.productSwatch.length > 0;
  const [selectedVariantSku, setSelectedVariantSku] = useState<string>(
    hasProductSwatch
      ? product.variantId
        ? product.variantId
        : product.productSwatch[0].sku || ''
      : ''
  );
  const handleVariantSelected = (productSwatch: ProductSwatch) => {
    setSelectedVariantSku(productSwatch.sku);
    setImagePreview(productSwatch.image.uri);
    setSelectedProduct?.(mapProductSwatchToProductTitleInfo(productSwatch, product));
  };

  return (
    <>
      {isShown && hasProductSwatch && (
        <Box className={styles.color_picker} data-testid="color-picker">
          {product.productSwatch.map(productSwatch => {
            return (
              <div
                data-testid="color-option"
                key={productSwatch.sku}
                className={cx(styles.container, [
                  {
                    [styles.selected]: selectedVariantSku === productSwatch.sku,
                  },
                ])}
                onClick={() => {
                  handleVariantSelected(productSwatch);
                }}
              >
                <div
                  data-testid="color-circle"
                  className={styles.circle}
                  style={{ backgroundColor: `#${productSwatch.rgbCode}` }}
                />
              </div>
            );
          })}
        </Box>
      )}
    </>
  );
};
