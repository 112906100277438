import { Button, Text } from '@hse24/shared-components';
import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GobackHeader from '../../../components/GoBackHeader/GobackHeader';
import SwipeToNavigateBack from '../../../components/SwipeToNavigateBack/SwipeToNavigateBack';
import { AvailableFlag, Flag } from '../../../config/FeatureFlags';
import {
  fetchLoggedInCreator,
  selectLoggedInCreator,
  validateAddress,
} from '../../state/creatorsSlice';
import FormikPersonalDataErrorsAlert from '../FormikErrorsAlert/FormikPersonalDataErrorsAlert';
import AddressRecommendationModal from './AddressRecommendationModal/AddressRecommendationModal';
import BusinessDataForm from './BusinessDataForm/BusinessDataForm';
import ContactDataForm from './ContactDataForm/ContactDataForm';
import ProfileDataForm from './ProfileDataForm/ProfileDataForm';
import ProfilePersonalData, {
  initialCreatorData,
  StreamerPersonalDataUi,
} from './ProfilePersonalData';
import streamerSchemaValidation from './SchemaValidation/StreamerSchemaValidation';
import SocialMediaForm from './SocialMediaForm/SocialMediaForm';
import style from './StreamerPersonalDataComponent.module.scss';

const StreamerPersonalDataComponent = () => {
  const dispatch = useDispatch();
  const profileData = useSelector(selectLoggedInCreator);

  useEffect(() => {
    dispatch(fetchLoggedInCreator());
  }, []);

  function onSubmit(personalDataUi: StreamerPersonalDataUi) {
    const personalData = uiToProfilePersonalData(personalDataUi);
    dispatch(validateAddress(personalData));
  }

  function uiToProfilePersonalData(personalData: StreamerPersonalDataUi): ProfilePersonalData {
    return {
      ...personalData,
      vatId: personalData.vatId || undefined,
      iban: personalData.iban?.replace(/\s+/gi, ''),
    };
  }

  return (
    <SwipeToNavigateBack>
      <div className={style.container}>
        <AddressRecommendationModal />
        <Formik
          initialValues={initialCreatorData}
          validationSchema={streamerSchemaValidation}
          onSubmit={onSubmit}
        >
          <Form>
            <Grid container direction={'column'} spacing={5}>
              <GobackHeader className={style.goback_header} />
              <Text.B1 className={style.title}>Öffentliches Profil</Text.B1>
              <Grid item>
                <ProfileDataForm />
              </Grid>
              <Flag flag={AvailableFlag.isStreamerGDPRDataEnabled}>
                <Grid item>
                  <ContactDataForm />
                </Grid>
                <Grid item>
                  <BusinessDataForm />
                </Grid>
              </Flag>
              <Grid item>
                <SocialMediaForm />
              </Grid>
              <Grid container direction="row" item justifyContent="flex-start" spacing={1}>
                <Grid item>
                  <FormikPersonalDataErrorsAlert />
                </Grid>
                <Grid item>
                  <Button disabled={!profileData}>
                    <Typography fontWeight={700}>Änderungen speichern</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </SwipeToNavigateBack>
  );
};

export default StreamerPersonalDataComponent;
