import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectCurrentLocation } from '../../app/store';
import { Audience } from '../../common/types';
import { removeCustomerIdFromLocalStorage, saveCustomerIdToLocalStorage } from '../../common/utils';
import Config from '../../config/config';
import creatorsApi from '../../creators/api/creatorsApi';
import { UpdateCreatorRequest } from '../../creators/api/requestResponse';
import validateAddressApi, { AddressValidateRequest } from '../../creators/api/validateAddressApi';
import { AddressRecommendation } from '../../creators/model/addressRecommendation';
import { fetchLoggedInCreatorPropagateError } from '../../creators/state/creatorsHandler';
import { resetCreatorsState, selectCreatorOnboarding } from '../../creators/state/creatorsSlice';
import { LogLevel } from '../../integration/logginglambda/LoggingLambdaApi';
import { logToLambda } from '../../integration/logginglambda/loggingSlice';
import { trackLoginSuccess } from '../../middlewares/reduxBeaconMiddleware/actions';
import { errorOccurred } from '../../notifications/state/notificationsSlice';
import routePaths from '../../routes/routePaths';
import { ApiReturnType } from '../../shared/ApiReturnType';
import { isAxiosError } from '../../shared/axiosClient';
import { parseError } from '../../utils/parseError/parseError';
import authApi, { UserLoginResponse, UserRole } from '../api/authApi';
import { Role } from '../role';
import {
  ActivateUserResponse,
  ConfirmEmail,
  ForgetPasswordOrigin,
  LoggedInCustomerPersonalDetailsResponse,
  LoginUserAction,
  LoginUserResponse,
  ReCaptchaAction,
  SetPasswordRequest,
  StreamerRegistrationRequest,
} from '../types';
import {
  activateUserAction,
  activateUserRedirectAction,
  ActivationData,
  ActivationStep,
  AuthStep,
  checkPersonalDetailsAction,
  confirmEmailExistence,
  createUserAccountAction,
  EmailExistenceStatus,
  fetchPersonalDetailsAction,
  forgetPasswordAction,
  loginUserAction,
  logout,
  redirectToAddressStepAction,
  redirectToLogin,
  resetAuthState,
  resetPasswordAction,
  selectAuthActivationProgressData,
  selectUserIsCreator,
  selectUserLogin,
  setActivationProcessAddressRecommendation,
  setActivationProcessError,
  setAuthActivationProcessData,
  setAuthActivationProcessStep,
  setAuthLoading,
  setAuthProcessEmail,
  setAuthProcessError,
  setAuthProcessLoading,
  setAuthProcessStep,
  setLoggedInCustomerPersonalDetail,
  setPasswordAction,
  setUserLogin,
  setVerifyEmailExistence,
  updatePersonalDetailsAction,
  UserLogin,
  userLoginState,
} from './authSlice';

function* redirectAfterLogin() {
  const location: ReturnType<typeof selectCurrentLocation> = yield select(selectCurrentLocation);
  const next: string | null = new URLSearchParams(location.search).get('redirect');

  let routePath: string = next ?? routePaths.landing;

  const userIsCreator: boolean = yield select(selectUserIsCreator);
  if (userIsCreator) {
    yield call(fetchLoggedInCreatorPropagateError);
    const creatorOnboarding: ReturnType<typeof selectCreatorOnboarding> = yield select(
      selectCreatorOnboarding
    );

    if (creatorOnboarding && !creatorOnboarding.hasCompletedProfile) {
      routePath = routePaths.creator.editProfile;
    }
  }

  yield put(push(routePath));
}

export function* redirectToLoginPageHandler() {
  // clear store
  yield put(resetAuthState());
  yield put(resetCreatorsState());

  // redirect to login
  yield put(push(routePaths.auth));
}

export function* logoutHandler() {
  try {
    yield call(authApi.logout);
    removeCustomerIdFromLocalStorage();
  } catch (unknownError) {
    if (!Config.env.isLocalEnv) yield put(errorOccurred(unknownError as Error));
  }

  window.nativeApp.logoutEvent();

  // clear store
  yield put(resetAuthState());
  yield put(resetCreatorsState());

  // redirect to login
  yield put(push(routePaths.auth));
}

export function* userLoginStateHandler() {
  try {
    yield put(setAuthLoading(true));

    const userLoginResponse: UserLoginResponse = yield call(authApi.getUserLoginState);
    const role = mapRole(userLoginResponse.role!, userLoginResponse.audiences?.[0]);
    if (role === Role.STREAMER_CLASSIC) {
      yield call(fetchLoggedInCreatorPropagateError);
    }
    const userLogin = { ...userLoginResponse, role } as UserLogin;
    yield put(setUserLogin(userLogin));
  } catch (error: unknown) {
    if (!is401Response(error)) {
      yield put(errorOccurred(error as Error));
    }
  } finally {
    yield put(setAuthLoading(false));
  }
}

function mapRole(role: UserRole, audience?: Audience): Role | null {
  switch (role) {
    case UserRole.STREAMER:
      return audience === Audience.HELLO ? Role.STREAMER_HELLO : Role.STREAMER_CLASSIC;
    case UserRole.INFLUENCER:
      return Role.INFLUENCER;
    case UserRole.AGENCY:
      return Role.AGENCY;
    case UserRole.HSE_EMPLOYEE:
      return Role.HSE_EMPLOYEE;
    default:
      return null;
  }
}

export function* confirmEmailExistenceHandler(action: PayloadAction<ConfirmEmail>) {
  const { executeRecaptcha } = action.payload;
  try {
    yield put(
      setVerifyEmailExistence({
        loading: true,
      })
    );

    if (executeRecaptcha) {
      const recaptchaToken: string = yield call(executeRecaptcha, ReCaptchaAction.USERNAME);
      yield call(authApi.isAccountExisting, action.payload.email, recaptchaToken);
    }

    yield put(
      setVerifyEmailExistence({
        loading: false,
        status: EmailExistenceStatus.EMAIL_EXIST,
      })
    );
  } catch (err: unknown) {
    yield put(
      setVerifyEmailExistence({
        loading: false,
        status: EmailExistenceStatus.EMAIL_NOT_EXISTS,
      })
    );
  }
}

export function* loginUserHandler(action: PayloadAction<LoginUserAction>) {
  const { credentials, executeRecaptcha } = action.payload;

  if (executeRecaptcha) {
    try {
      yield put(setAuthProcessError(undefined));
      yield put(setAuthProcessLoading(true));

      const recaptchaToken: string = yield call(executeRecaptcha, ReCaptchaAction.LOGIN);
      const loginResponse: LoginUserResponse = yield call(
        authApi.loginUserApi,
        credentials,
        recaptchaToken
      );

      yield put(setAuthProcessEmail(action.payload.credentials.email));

      const userLoginResponse: UserLoginResponse = yield call(authApi.getUserLoginState);
      const role = mapRole(userLoginResponse.role!, userLoginResponse.audiences?.[0]);
      const userLogin = {
        ...userLoginResponse,
        role,
      } as UserLogin;
      saveCustomerIdToLocalStorage(loginResponse.customerId);

      yield put(setUserLogin(userLogin));

      window.nativeApp.loginEvent({
        email: credentials.email,
        password: credentials.password,
      });

      yield put(trackLoginSuccess());

      yield call(redirectAfterLogin);
    } catch (err: unknown) {
      yield put(
        logToLambda({
          level: LogLevel.WARN,
          message: 'error occurred when trying to login',
          error: parseError(err),
        })
      );
      yield put(
        setAuthProcessError('Leider kennen wir diese Kombination aus E-Mail und Passwort nicht.')
      );
    } finally {
      yield put(setAuthProcessLoading(false));
    }
  }
}

export function* forgetPasswordHandler(action: PayloadAction<ForgetPasswordOrigin>) {
  const activationData: ActivationData | undefined = yield select(selectAuthActivationProgressData);
  yield put(setAuthProcessError(undefined));
  yield put(setAuthProcessStep(AuthStep.FORGET_PASSWORD_STEP));
  yield put(setAuthProcessEmail(activationData?.email));
  if (action.payload === ForgetPasswordOrigin.ONBOARDING) {
    yield put(push(routePaths.auth));
  }
}

export function* resetPasswordHandler(action: PayloadAction<string>) {
  try {
    yield put(setAuthProcessError(undefined));
    yield put(setAuthProcessLoading(true));
    yield call(authApi.resetPassword, action.payload);
    yield put(setAuthProcessEmail(action.payload));
    yield put(setAuthProcessStep(AuthStep.RESET_PASSWORD_EMAIL));
  } catch (err: unknown) {
    const error = err as AxiosError;
    yield put(
      logToLambda({
        level: LogLevel.WARN,
        message: 'error occurred when trying to reset password',
        error: parseError(err),
      })
    );
    yield put(setAuthProcessError(error?.message));
  } finally {
    yield put(setAuthProcessLoading(false));
  }
}

export function* activateUserHandler(action: PayloadAction<string>) {
  try {
    yield put(setAuthLoading(true));

    const { userId, email, isExistingAccount, role }: ActivateUserResponse = yield call(
      authApi.activateUser,
      {
        activationTokenId: action.payload,
      }
    );
    yield put(setAuthActivationProcessData({ id: userId, email, isExistingAccount, role }));
  } catch (unknownError: unknown) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when trying to activate user',
        error: parseError(unknownError),
      })
    );
    yield call(generateActivationError, unknownError as AxiosError);
    yield put(setAuthActivationProcessStep(ActivationStep.ERROR_OCCURRED));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* activateUserRedirectHandler() {
  const activationProgressData: ReturnType<typeof selectAuthActivationProgressData> = yield select(
    selectAuthActivationProgressData
  );
  switch (activationProgressData?.isExistingAccount) {
    case true:
      yield put(setAuthActivationProcessStep(ActivationStep.EXISTING_ACCOUNT_STEP));
      break;
    case false:
      yield put(setAuthActivationProcessStep(ActivationStep.NEW_ACCOUNT_STEP));
      break;
    default:
      yield put(setAuthActivationProcessStep(ActivationStep.WELCOME_STEP));
  }
}

export function* fetchPersonnelDetailsHandler() {
  try {
    const response: LoggedInCustomerPersonalDetailsResponse = yield call(
      authApi.getCustomerPersonalDetails
    );
    yield put(setLoggedInCustomerPersonalDetail(response));
  } catch (unknownError: unknown) {
  } finally {
  }
}

export function* checkPersonnelDetailsHandler(action: PayloadAction<UpdateCreatorRequest>) {
  try {
    const values = action.payload;
    yield put(setAuthLoading(true));

    const addressValidateRequest: AddressValidateRequest = yield call(
      valuesToAddressValidateRequest,
      values
    );

    const addressRecommendationResponse: ApiReturnType<typeof validateAddressApi.validateAddress> =
      yield call(validateAddressApi.validateAddress, addressValidateRequest);

    const addressRecommendation: AddressRecommendation = addressRecommendationResponse[0];

    const keysToCompare = ['street', 'streetNumber', 'zipCode', 'city'];

    const recommendationAddress = _.pick(addressRecommendation, keysToCompare);
    const originalAddress = _.pick(addressValidateRequest, keysToCompare);

    if (_.isEqual(recommendationAddress, originalAddress)) {
      yield put(updatePersonalDetailsAction(values));
    } else {
      yield put(setActivationProcessAddressRecommendation(addressRecommendation));
    }
  } catch (unknownError: unknown) {
    yield call(generateActivationError, unknownError as AxiosError);
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* updatePersonnelDetailsHandler(action: PayloadAction<UpdateCreatorRequest>) {
  try {
    const values = action.payload;
    yield put(setAuthLoading(true));

    yield put(setActivationProcessAddressRecommendation(undefined));

    const creator: ApiReturnType<typeof creatorsApi.getLoggedInCreator> = yield call(
      creatorsApi.getLoggedInCreator
    );

    if (creator?.id) {
      const userLogin: ReturnType<typeof selectUserLogin> = yield select(selectUserLogin);

      const ValuesWithName = { ...values, name: userLogin?.displayName };

      yield call(creatorsApi.updateCreator, ValuesWithName, creator.id);

      yield call(fetchLoggedInCreatorPropagateError);
      yield put(setAuthActivationProcessStep(ActivationStep.WELCOME_STEP));
      yield put(push(routePaths.creator.editProfile));
    }
  } catch (unknownError: unknown) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when trying to update personnel details during onboarding',
        error: parseError(unknownError),
      })
    );
    yield call(generateActivationError, unknownError as AxiosError);
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* createUserAccountHandler(action: PayloadAction<StreamerRegistrationRequest>) {
  try {
    yield put(setAuthLoading(true));

    yield call(authApi.streamersRegistration, action.payload);

    const activationProgressData: ReturnType<typeof selectAuthActivationProgressData> =
      yield select(selectAuthActivationProgressData);
    if (activationProgressData?.email) {
      window.nativeApp.loginEvent({
        email: activationProgressData?.email,
        password: action.payload.password,
      });
    }

    if (!activationProgressData?.role)
      yield put(setAuthActivationProcessStep(ActivationStep.WELCOME_STEP));
    else {
      yield put(userLoginState());
      switch (activationProgressData?.role) {
        case Role.STREAMER_HELLO || Role.INFLUENCER:
          yield put(setAuthActivationProcessStep(ActivationStep.ADDRESS_STEP));
          break;
        default: {
          yield put(push(routePaths.landing));
        }
      }
    }
  } catch (unknownError: unknown) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when trying to create a user account',
        error: parseError(unknownError),
      })
    );
    const axiosError = unknownError as AxiosError;
    yield put(setAuthProcessError(axiosError.message));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* setPasswordHandler(action: PayloadAction<SetPasswordRequest>) {
  try {
    yield put(setAuthLoading(true));
    yield call(authApi.setPassword, action.payload);
    yield put(userLoginState());
    const creator: ApiReturnType<typeof creatorsApi.getLoggedInCreator> = yield call(
      creatorsApi.getLoggedInCreator
    );
    window.nativeApp.loginEvent({ email: creator.email, password: action.payload.password });
    yield call(redirectAfterLogin);
  } catch (unknownError: unknown) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when trying to set password',
        error: parseError(unknownError),
      })
    );
    yield put(
      setAuthProcessError(
        'Ungültiger Link zum Zurücksetzen des Passworts, wenden Sie sich bitte an HSE'
      )
    );
    yield put(push(routePaths.auth));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* redirectToAddressStepHandler() {
  yield put(setAuthActivationProcessStep(ActivationStep.ADDRESS_STEP));
  yield put(push(routePaths.welcome));
}

export function* watcherAuthSagas() {
  yield takeLatest(redirectToLogin.type, redirectToLoginPageHandler);
  yield takeLatest(logout.type, logoutHandler);
  yield takeLatest(userLoginState.type, userLoginStateHandler);
  yield takeLatest(confirmEmailExistence.type, confirmEmailExistenceHandler);
  yield takeLatest(loginUserAction.type, loginUserHandler);
  yield takeLatest(forgetPasswordAction.type, forgetPasswordHandler);
  yield takeLatest(resetPasswordAction.type, resetPasswordHandler);
  yield takeLatest(activateUserAction.type, activateUserHandler);
  yield takeLatest(activateUserRedirectAction.type, activateUserRedirectHandler);
  yield takeLatest(fetchPersonalDetailsAction.type, fetchPersonnelDetailsHandler);
  yield takeLatest(checkPersonalDetailsAction.type, checkPersonnelDetailsHandler);
  yield takeLatest(updatePersonalDetailsAction.type, updatePersonnelDetailsHandler);
  yield takeLatest(createUserAccountAction.type, createUserAccountHandler);
  yield takeLatest(setPasswordAction.type, setPasswordHandler);
  yield takeLatest(redirectToAddressStepAction.type, redirectToAddressStepHandler);
}

const is401Response = (error: unknown) =>
  isAxiosError(error as Error) && (error as AxiosError).response?.status === 401;

export const valuesToAddressValidateRequest = (
  values: UpdateCreatorRequest
): AddressValidateRequest => {
  return {
    salutation: values.salutation!,
    firstName: values.firstName!,
    lastName: values.lastName!,
    street: values.street!,
    streetNumber: values.streetNumber!,
    zipCode: values.zipCode!,
    city: values.city!,
  };
};

function* generateActivationError(error: AxiosError) {
  const defaultMessage = 'Ein Fehler ist aufgetreten, bitte versuche es später noch einmal.';
  yield put(
    setActivationProcessError({
      message: error.response?.data.message || defaultMessage,
      detail: error.response?.data.detail || defaultMessage,
    })
  );
}
