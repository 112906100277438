import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { default as React, useState } from 'react';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import { ProductTileInfo } from '../../model/productTileInfo';
import ProductSelectionItem from './ProductSelectionItem/ProductSelectionItem';
import styles from './ProductsSelection.module.scss';

interface Props {
  product: ProductTileInfo;
  handleSelect: (product: ProductTileInfo) => void;
  handleRemove: (product: ProductTileInfo) => void;
  selectedProducts: ProductTileInfo[];
  addSoldOutProducts?: boolean;
}
function ProductWithSelector({
  product,
  handleSelect,
  handleRemove,
  selectedProducts,
  addSoldOutProducts,
}: Props) {
  const isSelected = !!selectedProducts.find(e =>
    e.baseProductNo.startsWith(product.baseProductNo)
  );
  const isSoldOut = product.outOfStock;

  const isEnabled = !!addSoldOutProducts || !isSoldOut;

  const [selectedProduct, setSelectedProduct] = useState(product);

  const handleItemClicked = () => {
    if (product.productSwatch?.length === 1) {
      if (isEnabled && !isSelected) {
        handleSelect(selectedProduct);
      } else {
        handleRemove(selectedProduct);
      }
    }
  };

  return (
    <Box
      className={classNames(styles.product, isSoldOut && styles.disabled_product)}
      onClick={handleItemClicked}
      data-testid="product-card"
    >
      <ProductSelectionItem
        setSelectedProduct={setSelectedProduct}
        isColorPickerShown={true}
        product={product}
        direction="row"
        addSoldOutProducts={addSoldOutProducts}
      />
      {isEnabled && (
        <Box className={styles.action}>
          {!isSelected ? (
            <div
              className={styles.extra_space}
              onClick={() => {
                isEnabled && handleSelect(selectedProduct);
              }}
            >
              <Button type={EButtonType.BLANK} className={styles.add_btn} size={EButtonSize.TINY}>
                <Icon
                  className={styles.add_icon}
                  icon={Icons.plusIcon}
                  dataTestId="AddCircleOutlineOutlinedIcon"
                />
              </Button>
            </div>
          ) : (
            <div className={styles.extra_space} onClick={() => handleRemove(product)}>
              <Button
                type={EButtonType.BLANK}
                size={EButtonSize.TINY}
                className={styles.checked_btn}
              >
                <Icon icon={Icons.checkIcon} dataTestId="CheckCircleIcon" className={styles.icon} />
              </Button>
            </div>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ProductWithSelector;
