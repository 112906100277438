import { MediaType } from '../api/postsRequestResponse';
import { MediaRequest, MediaResponse } from '../state/postsSlice';

export interface PostImageMetadata {
  fileExtension: string;
  imageUrl: string;
  mediaType?: MediaType;
}

export interface PostData {
  caption: string;
  baseProductsNo: string[];
  scheduleAt?: string;
  medias: MediaRequest[];
  type: ScheduledWhen;
}
export interface AdminPostData extends PostData {
  medias: MediaRequest[];
  salesTheme?: string;
}

export interface StreamPostData {
  caption?: string | null;
  baseProductsNo?: string[];
  scheduleAt?: string;
  salesTheme?: string;
  creatorId?: string;
  medias: MediaRequest[];
  type: ScheduledWhen;
}

export interface AdminStreamPostData extends StreamPostData {
  medias: MediaRequest[];
}

export interface Post {
  id: string;
  caption: string;
  imageUrl: string;
  likes: number;
  shares: number;
  commentsCount: number;
  createdAt: string;
  status: PostStatus;
  scheduleAt?: string;
  salesTopic?: string;
  medias?: MediaResponse[];
}

export enum PostStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED_HSE_EMPLOYEE = 'UNPUBLISHED_HSE_EMPLOYEE',
  UNPUBLISHED_CREATOR = 'UNPUBLISHED_CREATOR',
}

export enum PostUploadStatus {
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}
export enum PostUploadFileType {
  IMAGE,
  VIDEO,
  FILE,
}

export enum ScheduledWhen {
  IMMEDIATELY = 'IMMEDIATELY',
  PLANNED = 'PLANNED',
}
