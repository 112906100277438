import { IconTrash, Text } from '@hse24/shared-components';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import PriceInfo from '../../../../components/PriceInfo/PriceInfo';
import { ProductTileInfo } from '../../../model/productTileInfo';
import ImagePreview from '../../ImagePreview/ImagePreview';
import { ProductColorPicker } from '../ProductColorPicker/ProductColorPicker';
import t from './../../../../constants/translation';
import styles from './ProductSelectionItem.module.scss';

interface Props {
  direction: 'row' | 'column';
  onRemoveHandler?: () => void;
  product: ProductTileInfo;
  isColorPickerShown?: boolean;
  setSelectedProduct?: (product: ProductTileInfo) => void;
  isReserved?: boolean;
  addSoldOutProducts?: boolean;
}

function ProductSelectionItem({
  product,
  onRemoveHandler,
  direction,
  isColorPickerShown,
  setSelectedProduct,
  isReserved,
  addSoldOutProducts,
}: Props) {
  const [imagePreview, setImagePreview] = useState<string | undefined>(product.imageUri);

  const isColumn = direction === 'column';
  const isSoldOut = product.outOfStock;
  const isEnabled = !!addSoldOutProducts || !isSoldOut;

  const opacity = !isEnabled ? 0.5 : 1;

  const savingPercent =
    product.price.original?.savingPercent ?? product.price.reference?.savingPercent;

  return (
    <Box
      className={styles.product_item}
      display="flex"
      flexDirection={direction}
      alignItems="center"
    >
      <Box position="relative">
        <Box className={styles.image} sx={{ width: 138, opacity }}>
          {onRemoveHandler && (
            <Box className={styles.remove}>
              <IconButton onClick={onRemoveHandler} data-testid="remove-button">
                <IconTrash width="24px" height="24px" />
              </IconButton>
            </Box>
          )}
          <ImagePreview alt={product.name} imageUrl={imagePreview} size={320} />
          {!!savingPercent && (
            <Text.BodySmall className={styles.saving_percent}>-{savingPercent}%</Text.BodySmall>
          )}
        </Box>
        {isSoldOut && (
          <Chip
            label={
              <Typography
                fontSize={isEnabled ? 12 : 10}
                fontWeight={isEnabled ? 700 : 500}
                lineHeight={isEnabled ? 18 : undefined}
                color="white"
              >
                {isEnabled ? t.common['Sold out'].toUpperCase() : t.common['Sold out']}
              </Typography>
            }
            className={isEnabled ? styles.reserved_soldout : styles.chip}
          />
        )}
      </Box>
      <Box
        sx={
          isColumn
            ? { width: 138, marginLeft: 0, opacity }
            : { marginLeft: 1, width: 'object-fit', opacity }
        }
      >
        <Typography className={styles.substring} variant="caption">
          {isReserved ? product.baseProductNo : product.brandName}
        </Typography>
        <Typography className={styles.product_name} variant="body2">
          {isReserved ? t.common['Unpublished article'] : product.name}
        </Typography>
        <Box>
          {!isReserved && (
            <PriceInfo
              price={product.price}
              isColumn={isColumn}
              hideSavingPercentage
              useOriginalPriceReference={!!product.price.original}
            />
          )}
        </Box>
        <ProductColorPicker
          setSelectedProduct={setSelectedProduct}
          isShown={isColorPickerShown}
          setImagePreview={setImagePreview}
          product={product}
        />
      </Box>
    </Box>
  );
}

export default ProductSelectionItem;
