import { Button, EButtonSize, EButtonType, IconTrash } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import { deleteChatMessage, StreamChatMessage } from '../../state/streamChatSlice';
import DeleteChatMsgDialog from '../DeleteChatMsgDialog/DeleteChatMsgDialog';
import styles from './ChatListItem.module.scss';

interface ChatItemProps {
  chatMessage: StreamChatMessage;
  fontSize: number;
  isFollow?: boolean;
}

const ChatListItem = ({ chatMessage, fontSize, isFollow }: ChatItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
  const handleCloseDeleteDialog = () => setIsDeletionDialogOpen(false);
  const dispatch = useDispatch();
  const openDeleteDialog = () => {
    setIsDeletionDialogOpen(true);
  };

  const handleDelete = () => {
    const currentUrl = window.location.href;
    const showId = currentUrl.split('/').reverse()[0];
    dispatch(deleteChatMessage({ messageId: chatMessage.messageId, showId: showId }));
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <DeleteChatMsgDialog
        handleClose={handleCloseDeleteDialog}
        openDialog={isDeletionDialogOpen}
        handleDelete={handleDelete}
      />
      <Box
        className={classNames(styles.item, {
          [styles.follow]: Boolean(isFollow),
        })}
        style={{ fontSize: fontSize, padding: `${fontSize - 8}px` }}
      >
        {chatMessage.isStreamerFollower && (
          <Box className={styles.icon}>
            <Icon dataTestId={'streamer-follower-icon'} icon={Icons.verifiedUser} />
          </Box>
        )}
        <Box className={styles.box}>
          <p className={styles.message}>
            <span className={styles.username}>{chatMessage.username}</span>
            <span className={styles.content}>{chatMessage.message}</span>
          </p>
        </Box>
      </Box>
      {isHovered && (
        <Button
          onClick={openDeleteDialog}
          size={EButtonSize.SMALL}
          className={styles.delete_button}
          type={EButtonType.BLANK}
          data-testid="delete-message-button"
        >
          <div className={styles.text_btn}>
            <Typography fontWeight={700} fontSize={16}>
              {t.common['Delete message']}
            </Typography>
            <IconTrash width="20px" height="20px" />
          </div>
        </Button>
      )}
    </div>
  );
};
export default ChatListItem;
