import { Button, EButtonSize, EButtonType, EInputTypes } from '@hse24/shared-components';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import ProductSelectionItem from '../../../products/components/ProductsSelection/ProductSelectionItem/ProductSelectionItem';
import ProductsSelection from '../../../products/components/ProductsSelection/ProductsSelection';
import { ProductTileInfo } from '../../../products/model/productTileInfo';
import {
  removeFromProductsSelectionSelectedProducts,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import styles from './ShowProductsSelection.module.scss';

interface Props {
  hasError?: boolean;
  isButton?: boolean;
  isAdmin?: boolean;
  canReserveProducts?: boolean;
  isFormShow?: boolean;
}
const ShowProductsSelection = ({
  hasError,
  isButton,
  isAdmin,
  canReserveProducts = false,
  isFormShow,
}: Props) => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { selectedProducts } = useSelector(selectProductSelection);
  const closeProductsSelection = () => setOpen(false);
  const openProductsSelection = () => setOpen(true);

  useEffect(() => {
    // We have to update the form values with added products from the search. Those values build a request createNewShow which will be sent to the backend.
    const productsIDs: string[] = selectedProducts.map(product => product.baseProductNo);
    setFieldValue('baseProductsNo', productsIDs);
    // We have to trigger a validation here...
    // The 'shouldValidate' flag is broken (see: https://github.com/formium/formik/issues/2059)
    const validateSelectedProductsTimer = setTimeout(
      () => setFieldTouched('baseProductsNo', true),
      1
    );

    return () => clearTimeout(validateSelectedProductsTimer);
  }, [selectedProducts]);

  const onRemoveProduct = (product: ProductTileInfo) => {
    return dispatch(removeFromProductsSelectionSelectedProducts(product));
  };

  return (
    <>
      <Box className={!hasError ? styles.products : styles.error_product}>
        {isButton ? (
          <Button
            className={styles.product_button}
            onClick={openProductsSelection}
            type={EButtonType.BLANK}
            size={EButtonSize.SMALL}
            inputType={EInputTypes.button}
          >
            Artikel hinzufügen
            <Icon icon={Icons.marketplaceIcon} className={styles.aside_icon} />
          </Button>
        ) : (
          <>
            <MenuItem className={styles.menu_item}>
              <ListItemIcon>
                <Icon dataTestId="clothes-hanger-icon" icon={Icons.clothesHanger} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" fontWeight="bold">
                  {t.creators.post['Add products']}
                </Typography>
              </ListItemText>
            </MenuItem>
            <Typography className={styles.secondary_text} variant="body1" gutterBottom>
              {t.creators.post['Add up to 50 products.*']}
            </Typography>
            <Paper
              onClick={openProductsSelection}
              className={!hasError ? styles.card : styles.card_error}
              elevation={0}
            >
              <IconButton className={styles.icon}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Paper>
          </>
        )}
        <Box
          className={cx({
            [styles.horizontal_list]: !hasError && selectedProducts?.length,
            [styles.hide]: !selectedProducts?.length,
          })}
        >
          {selectedProducts.map(product => (
            <ProductSelectionItem
              key={product.baseProductNo}
              product={product}
              onRemoveHandler={() => onRemoveProduct(product)}
              direction="column"
              isReserved={product.isReserved}
              addSoldOutProducts={canReserveProducts}
            />
          ))}
        </Box>
      </Box>
      {isOpen && (
        <ProductsSelection
          handleClose={closeProductsSelection}
          isAdmin={isAdmin}
          canReserveProducts={canReserveProducts}
          isFormShow={isFormShow}
        />
      )}
    </>
  );
};

export default ShowProductsSelection;
