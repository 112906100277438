import { Error, ETag, Radio, Text } from '@hse24/shared-components';
import { Grid } from '@mui/material';
import { format, subMinutes } from 'date-fns';
import { ErrorMessage, FormikTouched, FormikValues } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreatorsSearchDropdown from '../../../../components/CreatorsSearchDropdown/CreatorsSearchDropdown';
import TextAreaField from '../../../../components/TextAreaField/TextAreaField';
import TextField from '../../../../components/TextField/TextField';
import t from '../../../../constants/translation';
import { selectProductSelection } from '../../../../products/state/productsSlice';
import DateSelector from '../../../../shows/component/ScheduleShowForm/ShowStartTimePicker/DateSelector/DateSelector';
import ShowStartTimePicker from '../../../../shows/component/ScheduleShowForm/ShowStartTimePicker/ShowStartTimePicker';
import ShowProductsSelection from '../../../../shows/component/ShowProductsSelection/ShowProductsSelection';
import { ScheduledWhen } from '../../../model/post';
import { setCreatorId } from '../../../state/postsSlice';
import { PostData } from '../../CreateEditPostForm/CreateEditPostForm';
import MusicLicenseWarning from '../../MusicLicenseWarning/MusicLicenseWarning';
import { AdminPostData } from '../AdminCreateEditPostForm';
import styles from './DataEntryForm.module.scss';

interface DataEntryFormProps {
  formValues: AdminPostData | PostData;
  setFormValue: (field: string, value?: string | Date) => void;
  errors: FormikErrors<FormikValues>;
  isSubmitClicked: boolean;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setFieldValue: (field: string, value?: string | Date, shouldValidate?: boolean) => void;
  touched: FormikTouched<FormikValues>;
  nowOrLater: ScheduledWhen;
  setNowOrLater: (value: ScheduledWhen) => void;
  caption?: string;
  isAdmin?: boolean;
}

const DataEntryForm: FC<DataEntryFormProps> = ({
  formValues,
  setFormValue,
  errors,
  isSubmitClicked,
  setFieldTouched,
  setFieldValue,
  touched,
  nowOrLater,
  setNowOrLater,
  caption,
  isAdmin,
}: DataEntryFormProps) => {
  const nowSub5min = useMemo(() => subMinutes(new Date(), 5), []);

  const { selectedProducts } = useSelector(selectProductSelection);
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const [isShowStartTimePickerVisible, setIsShowStartTimePickerVisible] = useState(
    formValues.scheduleAt !== undefined
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsShowStartTimePickerVisible(nowOrLater === ScheduledWhen.PLANNED);
  }, [nowOrLater]);

  const getScheduleAt = () => formValues.scheduleAt?.toISOString() || '';

  const renderErrorMsg = (message: string) => {
    return <Error className={styles.error_message} error={message} />;
  };

  const onPlannedOptionSelected = () => {
    if (nowOrLater === ScheduledWhen.PLANNED) return;
    setNowOrLater(ScheduledWhen.PLANNED);
    setIsDateSelectorOpen(true);
  };

  return (
    <>
      {'creatorId' in formValues && (
        <Grid item xs={12}>
          <Text.Body tag={ETag.DIV} className={styles.title}>
            Streamer*
          </Text.Body>
          <CreatorsSearchDropdown
            creatorId={formValues.creatorId}
            setCreatorId={value => {
              setFormValue('creatorId', value);
              dispatch(setCreatorId(value));
            }}
          />
          {!formValues.creatorId &&
            isSubmitClicked &&
            renderErrorMsg(t.validation['Please select a creator'])}
        </Grid>
      )}
      <Grid item xs={12}>
        <Text.Body tag={ETag.DIV} className={styles.title}>
          {t.creators.post['ScheduleAt']}
        </Text.Body>
        <div className={styles.show_start_time}>
          <div className={styles.radio}>
            <Radio
              selected={nowOrLater === ScheduledWhen.IMMEDIATELY}
              onSelect={() => setNowOrLater(ScheduledWhen.IMMEDIATELY)}
              className={styles.ration_option}
            >
              {t.common['Now']}
            </Radio>
            <Radio
              selected={nowOrLater === ScheduledWhen.PLANNED}
              onSelect={onPlannedOptionSelected}
              className={styles.ration_option}
            >
              {t.common['Planned']}
            </Radio>
          </div>
          {isShowStartTimePickerVisible && (
            <>
              <ShowStartTimePicker
                scheduledStartAt={getScheduleAt()}
                onClick={() => setIsDateSelectorOpen(true)}
                hasError={errors.scheduledStartAt != undefined && touched.scheduledStartAt == true}
                isButton
              />
              <div className={styles.date_Selector}>
                <TextField
                  data-testid="scheduledStartAt"
                  fieldName="scheduledStartAt"
                  value={format(formValues.scheduleAt ?? nowSub5min, 'dd.MM.yyyy HH:mm')}
                  label="Startzeit"
                />
              </div>
              <ErrorMessage name="scheduledStartAt">
                {() => renderErrorMsg(t.validation['Please set a time'])}
              </ErrorMessage>
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Text.Body tag={ETag.DIV} className={styles.title}>
          Beschreibung*
        </Text.Body>
        <TextAreaField
          fieldName="caption"
          label="Maximal 2.200 Zeichen"
          value={caption ?? ''}
          onChange={value => setFormValue('caption', value ?? '')}
          isSubmitClicked={isSubmitClicked}
          rows={5}
          maxChars={2200}
        />
      </Grid>
      <Grid item xs={12}>
        <Text.Body tag={ETag.DIV} className={styles.title}>
          Produkte*
        </Text.Body>
        <ShowProductsSelection
          hasError={
            errors.baseProductsNo !== undefined &&
            isSubmitClicked &&
            (selectedProducts == undefined || selectedProducts.length == 0)
          }
          isButton
          isAdmin={isAdmin}
          canReserveProducts={isAdmin}
        />
        {errors.baseProductsNo !== undefined &&
          isSubmitClicked &&
          (selectedProducts == undefined || selectedProducts.length == 0) && (
            <ErrorMessage name="baseProductsNo">
              {() => renderErrorMsg(t.validation['Please include one or more products'])}
            </ErrorMessage>
          )}
        {!isAdmin && (
          <Grid item xs={12} className={styles.music_licence_popup}>
            <MusicLicenseWarning />
          </Grid>
        )}
      </Grid>
      {'salesTheme' in formValues && (
        <Grid item xs={12}>
          <Text.Body tag={ETag.DIV} className={styles.title}>
            Sales Thema/Kampagne
          </Text.Body>
          <TextField
            fieldName="salesTheme"
            value={formValues?.salesTheme}
            onChange={value => setFormValue('salesTheme', value ?? '')}
            label="Thema oder Basiswording"
          />
          <Text.BodySmall className={styles.sales_topic_hint}>
            {t.common['Not visible to customers']}
          </Text.BodySmall>
        </Grid>
      )}
      {isDateSelectorOpen && (
        <DateSelector
          title={t.creators.post.DateSelectorTitle}
          isDialogOpen={isDateSelectorOpen}
          closeDialog={() => {
            setIsDateSelectorOpen(false);
          }}
          plannedAt={formValues.scheduleAt ?? new Date()}
          fetchAllSlots={true}
          updatePlannedAt={(date: Date) => {
            setFormValue('scheduleAt', date);
            setFieldTouched('scheduledStartAt', true);
            setFieldValue('scheduledStartAt', format(date, 'dd.MM.yyyy HH:mm'));
          }}
        />
      )}
    </>
  );
};

export default DataEntryForm;
