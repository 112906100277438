import {
  Button,
  EButtonType,
  EInputTypes,
  Error,
  ETag,
  Radio,
  Text,
} from '@hse24/shared-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import t from '../../../constants/translation';
import {
  initializeStressTestChat,
  MessageType,
  sendFollowEvent,
  sendMessage,
} from '../../../streamChat/state/streamChatSlice';
import { validationSchema } from '../../../utils/validationSchemas/ShowStressTestValidationSchema';
import { usernames } from '../../constants/StressTestUsernames';
import styles from './ShowStressTestForm.module.scss';

interface FormValues {
  username: string;
  actionType: MessageType;
  chatMessage?: string;
  requestPerSecond: number;
  showId: string;
}

interface WorkerMessage {
  message: string;
  chatRoomId: string;
  userName: string;
}

export interface ShowStressTestFormProps {
  showId: string;
  showTitle: string;
  streamerName: string;
}

const ShowStressTestForm: React.FC<ShowStressTestFormProps> = ({
  showId,
  showTitle,
  streamerName,
}) => {
  const [worker, setWorker] = useState<Worker>(new Worker(`${process.env.PUBLIC_URL}/Worker.js`));
  const [isSubmitted, setSubmitted] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const dispatch = useDispatch();
  const chatRoomId = useMemo(() => crypto.randomUUID(), []);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const initialValues: FormValues = {
    username: 'Lukas',
    actionType: MessageType.REACTION,
    chatMessage: undefined,
    requestPerSecond: 10,
    showId: showId || '',
  };

  useEffect(() => {
    if (isSubmitted) {
      setElapsedTime(0);
      timerRef.current = setInterval(() => {
        setElapsedTime(prev => prev + 1);
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isSubmitted]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isSubmitted) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isSubmitted]);

  const handleSubmit = (values: FormValues) => {
    const updatedValues = { ...values, showId: showId || '' };
    if (!isSubmitted && updatedValues.showId) {
      dispatch(
        initializeStressTestChat({ stressChatRoomId: chatRoomId, showId: updatedValues.showId })
      );
      if (window.Worker) {
        const getMessage = () => {
          if (updatedValues.actionType === MessageType.REACTION) {
            return '❤️';
          } else if (updatedValues.actionType === MessageType.MESSAGE) {
            return values.chatMessage;
          }
        };
        const getRequestPerSecond = () => {
          if (updatedValues.actionType === MessageType.FOLLOW) {
            return 1;
          }
          return values.requestPerSecond;
        };

        worker.postMessage({
          chatRoomId,
          userName: values.username,
          message: getMessage(),
          requestPerSecond: getRequestPerSecond(),
        });

        worker.onmessage = (event: MessageEvent<WorkerMessage>) => {
          dispatch(
            updatedValues.actionType === MessageType.FOLLOW
              ? sendFollowEvent({
                  chatRoomId,
                  userName: values.username,
                  streamerName: streamerName,
                  requestsPerSecond: values.requestPerSecond,
                })
              : sendMessage({
                  ...event.data,
                  showId: updatedValues.showId,
                  messageType: updatedValues.actionType,
                })
          );
        };
      }
      setSubmitted(true);
    } else {
      setDialogOpen(true);
    }
  };

  const confirmStopTest = () => {
    if (!!showId) {
      worker.terminate();
      setWorker(new Worker(`${process.env.PUBLIC_URL}/Worker.js`));
      setSubmitted(false);
      setDialogOpen(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      {showTitle && (
        <Text.B1 tag={ETag.H1} className={styles.page_title}>
          {`${t.admins.upcomingShowsOverview['Stress test for the show']}: `}
          <br />
          {showTitle}
        </Text.B1>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ values, setFieldValue, isValid }) => (
          <Form data-testid="show-stress-test-form">
            <div className={styles.form_group}>
              <Text.Body tag={ETag.DIV} className={styles.title}>
                Benutzername
              </Text.Body>
              <Field
                as="select"
                name="username"
                className={styles.select}
                data-testid="username-select"
                disabled={isSubmitted}
              >
                {usernames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="username">
                {msg => <Error error={msg} className={styles.error_message} />}
              </ErrorMessage>
            </div>

            <div className={styles.form_group}>
              <Text.Body tag={ETag.DIV} className={styles.title}>
                Aktion auswählen
              </Text.Body>
              <div className={styles.radio_group}>
                {Object.values(MessageType).map(action => (
                  <Radio
                    key={action}
                    selected={values.actionType === action}
                    onSelect={() => setFieldValue('actionType', action)}
                    data-testid={`action-radio-${action}`}
                    disabled={isSubmitted}
                  >
                    {action.charAt(0).toUpperCase() + action.slice(1).toLowerCase()}
                  </Radio>
                ))}
              </div>
              <ErrorMessage name="actionType">
                {msg => <Error error={msg} className={styles.error_message} />}
              </ErrorMessage>
              {values.actionType === MessageType.MESSAGE && (
                <div className={styles.form_group}>
                  <Text.Body tag={ETag.DIV} className={styles.chat}>
                    Chatnachricht
                  </Text.Body>
                  <Field
                    name="chatMessage"
                    type="text"
                    placeholder="Geben Sie eine Nachricht ein"
                    className={styles.input}
                    data-testid="chat-message-input"
                    disabled={isSubmitted}
                  />
                  <ErrorMessage name="chatMessage">
                    {msg => <Error error={msg} className={styles.error_message} />}
                  </ErrorMessage>
                </div>
              )}
            </div>

            <div className={styles.form_group}>
              <Text.Body tag={ETag.DIV} className={styles.title}>
                Anfragen pro Sekunde
              </Text.Body>
              <Field
                as="select"
                name="requestPerSecond"
                className={styles.select}
                data-testid="request-per-second-select"
                disabled={isSubmitted}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map(num => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="requestPerSecond" data-testid="error-username">
                {msg => <Error error={msg} className={styles.error_message} />}
              </ErrorMessage>
            </div>

            <Text.Body tag={ETag.DIV} className={styles.title} data-testid="test-timer">
              {isSubmitted
                ? `Test läuft seit: ${elapsedTime} Sekunden`
                : elapsedTime > 0
                ? `Test lief für: ${elapsedTime} Sekunden`
                : null}
            </Text.Body>

            <div className={styles.button_group}>
              <Button
                type={EButtonType.PRIMARY}
                inputType={EInputTypes.button}
                data-testid="start-button"
                disabled={!isValid}
                className={isSubmitted ? styles.buttonRunning : ''}
                onClick={e => {
                  e.preventDefault();
                  handleSubmit(values);
                }}
              >
                <Text.Body tag={ETag.SPAN}>
                  {isSubmitted ? 'Test beenden' : 'Test starten'}
                </Text.Body>
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <StyledDialog
        dialogProps={{
          open: isDialogOpen,
          maxWidth: 'sm',
        }}
        close={() => setDialogOpen(false)}
        title="Belastungstest beenden"
        body="Möchten Sie den Belastungstest wirklich beenden?"
        submit={{
          title: 'Ja, beenden',
          action: confirmStopTest,
          loading: false,
        }}
        cancel={{
          title: 'Abbrechen',
          action: () => setDialogOpen(false),
          loading: false,
        }}
        testId="stop-test-dialog"
      />

      <RouteLeavingGuard blockNavigation={isSubmitted} onAbort={confirmStopTest} />
    </div>
  );
};

export default ShowStressTestForm;
