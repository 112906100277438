import { Box, Grid, Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import AccountMenu from '../AccountMenu/AccountMenu';
import {
  desktopNavigationTabs,
  getNameOfActiveNavigationItemByCurrentLocation,
} from '../navigationItems';
import styles from './MobileNavbar.module.scss';

export const MobileNavbar = () => {
  const cleanedDesktopNavigationTabs = {
    ...desktopNavigationTabs,
  };

  const titleOfPage = useSelector(
    getNameOfActiveNavigationItemByCurrentLocation(cleanedDesktopNavigationTabs)
  );
  return (
    <Box className={styles.appbar} data-testid={'mobile-navbar'}>
      <Toolbar className={styles.toolbar}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item className={styles.title} data-testid="mobile-title">
            {titleOfPage}
          </Grid>
          <Grid item>
            <AccountMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  );
};
