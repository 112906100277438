import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import t from '../../../constants/translation';
import style from './DeleteChatMsgDialog.module.scss';
interface Props {
  openDialog: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}
function DeleteChatMsgDialog({ openDialog, handleClose, handleDelete }: Props) {
  const submit = () => {
    handleClose();
    handleDelete();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth={false} // Prevent default MUI width constraints
      sx={{
        '& .MuiDialog-paper': {
          width: '560px', // Set dialog width
        },
      }}
    >
      <DialogTitle>
        <Typography className={style.dialog_header}>{t.common['Confirm deletion']}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={style.content_msg}>
          {t.common['Are you sure you want to delete this chat message?']}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
          padding: '16px',
        }}
      >
        <div>
          <Button type={EButtonType.BLANK} size={EButtonSize.SMALL} onClick={handleClose}>
            <Typography fontWeight={700} fontSize={16}>
              {t.common.Cancel}
            </Typography>
          </Button>
        </div>
        <div>
          <Button type={EButtonType.PRIMARY} onClick={submit} size={EButtonSize.SMALL}>
            <Typography fontWeight={700} fontSize={16}>
              {t.common['Delete message']}
            </Typography>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteChatMsgDialog;
