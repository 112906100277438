import classNames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import React, { FC, ReactNode } from 'react';

interface ContentCarouselStyles {
  contentSlider?: string;
  emblaViewport?: string;
  slidesWrapper?: string;
  slide?: string;
}
interface Props {
  children?: ReactNode;
  styles?: ContentCarouselStyles;
}

const ContentCarousel: FC<Props> = ({ children, styles: customStyles = {} }) => {
  const [emblaRef] = useEmblaCarousel({ loop: false, align: 'start', containScroll: 'keepSnaps' });

  const ContentSlider = classNames(customStyles.contentSlider);
  const EmblaViewport = classNames(customStyles.emblaViewport);
  const SlidesWrapper = classNames(customStyles.slidesWrapper);
  const Slide = classNames(customStyles.slide);

  return (
    <div className={ContentSlider}>
      <div className={EmblaViewport} ref={emblaRef}>
        <div className={SlidesWrapper}>
          {React.Children.map(children, (child, index) => (
            <div className={Slide} key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentCarousel;
