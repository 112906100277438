import { Add } from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CameraIndoorOutlinedIcon from '@mui/icons-material/CameraIndoorOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import { createSelector } from '@reduxjs/toolkit';
import { Location } from 'history';
import { ReactNode } from 'react';
import { matchPath } from 'react-router-dom';
import { selectCurrentLocation } from '../../app/store';
import Icons from '../../assets';
import { Role } from '../../auth/role';
import { selectUserRoles } from '../../auth/state/authSlice';
import Icon from '../../components/Icon/Icon';
import ProfileImageAvatar from '../../components/ProfileImageAvatar/ProfileImageAvatar';
import Config from '../../config/config';
import t from '../../constants/translation';
import routePaths from '../../routes/routePaths';

export enum NavigationItemType {
  TAB = 'TAB',
  BUTTON = 'BUTTON',
}

export interface NavigationItem {
  name: string;
  path?: string | undefined;
  icon?: ReactNode;
  type?: NavigationItemType;
  secondaryPaths?: string[];
}

const helloStreamerAndInfluencerStandardNavigationItems: NavigationItem[] = [
  {
    name: 'Übersicht',
    path: routePaths.creator.dashboard,
    icon: <HomeOutlinedIcon />,
    secondaryPaths: [routePaths.creator.academy.overview, routePaths.creator.academy.video],
  },
  {
    name: 'Produkte',
    path: routePaths.creator.products,
    icon: <StorefrontOutlinedIcon />,
  },
  { name: 'Erstellen', icon: <Add />, type: NavigationItemType.BUTTON },
  {
    name: 'Analytics',
    path: routePaths.creator.analytics,
    icon: <AnalyticsOutlinedIcon />,
  },
];

const classicStreamerStandardNavigationItems: NavigationItem[] = [
  {
    name: 'Übersicht',
    path: routePaths.creator.classicDashboard,
    icon: <Icon icon={Icons.homeIcon} style={{ margin: '4px' }} dataTestId="home-icon" />,
  },
  {
    name: 'Erstellen',
    icon: <Icon icon={Icons.plusIcon} style={{ margin: '4px' }} dataTestId="plus-icon" />,
    path: routePaths.creator.contentCreation,
    secondaryPaths: [routePaths.creator.addPost, routePaths.creator.addShow],
  },
  ...(Config.env.flags.isPostCreationEnabled
    ? [
        {
          name: 'Insights',
          path: routePaths.creator.insights,
          icon: (
            <Icon
              icon={Icons.chartTrending}
              style={{ margin: '4px' }}
              dataTestId="chart-trending-icon"
            />
          ),
          secondaryPaths: [routePaths.creator.updatePost],
        },
      ]
    : []),
  {
    name: 'Account',
    path: routePaths.creator.profileDashboard,
    secondaryPaths: [routePaths.creator.editProfile, routePaths.creator.streamSettings],
    icon: <ProfileImageAvatar size={24} forBottomNavbar />,
  },
];

const hseEmployeeNavigationItems: NavigationItem[] = [
  {
    name: t.agencies.Agencies,
    path: routePaths.hseEmployee.agenciesOverview,
    secondaryPaths: [routePaths.hseEmployee.agencyDetails, routePaths.hseEmployee.addAgency],
    icon: <CameraIndoorOutlinedIcon />,
  },
  {
    name: 'Streamer',
    path: routePaths.hseEmployee.creatorsOverview,
    secondaryPaths: [routePaths.hseEmployee.creatorDetails],
    icon: <VideoCameraFrontOutlinedIcon />,
  },
  {
    name: 'Geplante Streams',
    path: routePaths.hseEmployee.upcomingShowsOverview,
    secondaryPaths: [routePaths.hseEmployee.addShow, routePaths.hseEmployee.editShow],
    icon: <LiveTvIcon />,
  },
  {
    name: 'Vergangene Streams',
    path: routePaths.hseEmployee.pastShowsOverview,
    secondaryPaths: [routePaths.hseEmployee.showDetails],
    icon: <ResetTvIcon />,
  },
  {
    name: 'Posts',
    path: routePaths.hseEmployee.postsOverview,
    secondaryPaths: [routePaths.hseEmployee.createPost, routePaths.hseEmployee.updateStreamerPost],
    icon: <WysiwygOutlinedIcon />,
  },
  {
    name: 'Feedback',
    path: routePaths.hseEmployee.surveysOverview,
    icon: <SentimentVerySatisfiedIcon />,
  },
];

const hseEmployeeNavigationItemsMobile: NavigationItem[] = [
  {
    name: 'Partner',
    path: routePaths.hseEmployee.agenciesOverview,
    secondaryPaths: [routePaths.hseEmployee.agencyDetails, routePaths.hseEmployee.addAgency],
    icon: (
      <Icon
        icon={Icons.opticalAlignmentFrameIcon}
        style={{ margin: '4px' }}
        height={32}
        width={32}
        dataTestId="optical-alignment-frame-icon"
      />
    ),
  },
  {
    name: 'Streamer',
    path: routePaths.hseEmployee.creatorsOverview,
    secondaryPaths: [routePaths.hseEmployee.creatorDetails],
    icon: (
      <Icon
        icon={Icons.userSmallIcon}
        style={{ margin: '4px' }}
        height={32}
        width={32}
        dataTestId="user-small-icon"
      />
    ),
  },
  {
    name: 'Geplant',
    path: routePaths.hseEmployee.upcomingShowsOverview,
    icon: (
      <Icon
        icon={Icons.calendarIcon}
        style={{ margin: '4px' }}
        height={32}
        width={32}
        dataTestId="calender-icon"
      />
    ),
  },
  {
    name: 'Streams',
    path: routePaths.hseEmployee.pastShowsOverview,
    icon: (
      <Icon
        icon={Icons.videosIcon}
        style={{ margin: '4px' }}
        height={32}
        width={32}
        dataTestId="videos-icon"
      />
    ),
  },
  {
    name: 'Posts',
    path: routePaths.hseEmployee.postsOverview,
    icon: (
      <Icon
        icon={Icons.imageIcon}
        style={{ margin: '4px' }}
        height={32}
        width={32}
        dataTestId="image-icon"
      />
    ),
  },
  {
    name: 'Feedback',
    path: routePaths.hseEmployee.surveysOverview,
    icon: (
      <Icon
        icon={Icons.commentsIcon}
        style={{ margin: '4px' }}
        height={32}
        width={32}
        dataTestId="comments-icon"
      />
    ),
  },
];

const agencyNavigationItems: NavigationItem[] = [
  {
    name: 'Streamer',
    path: routePaths.agency.creatorsOverview,
    icon: <VideoCameraFrontOutlinedIcon />,
  },
  {
    name: 'Vergangene Streams',
    path: routePaths.agency.pastShowsOverview,
    icon: <ResetTvIcon />,
  },
];

const creatorProfileNavigationItem: NavigationItem = {
  name: 'Profil',
  path: routePaths.creator.profileDashboard,
  secondaryPaths: [routePaths.creator.editProfile],
  icon: <AccountCircleOutlinedIcon />,
};

export const desktopNavigationTabs: Record<Role, NavigationItem[]> = {
  [Role.STREAMER_HELLO]: helloStreamerAndInfluencerStandardNavigationItems,
  [Role.STREAMER_CLASSIC]: classicStreamerStandardNavigationItems,
  [Role.INFLUENCER]: helloStreamerAndInfluencerStandardNavigationItems,
  [Role.AGENCY]: agencyNavigationItems,
  [Role.HSE_EMPLOYEE]: hseEmployeeNavigationItems,
};

export const accountMenuItems: Record<Role, NavigationItem[]> = {
  [Role.STREAMER_HELLO]: [creatorProfileNavigationItem],
  [Role.STREAMER_CLASSIC]: [creatorProfileNavigationItem],
  [Role.INFLUENCER]: [creatorProfileNavigationItem],
  [Role.AGENCY]: [],
  [Role.HSE_EMPLOYEE]: [],
};

export const bottomNavigationItems: Record<Role, NavigationItem[]> = {
  [Role.STREAMER_HELLO]: [
    ...helloStreamerAndInfluencerStandardNavigationItems,
    creatorProfileNavigationItem,
  ],
  [Role.STREAMER_CLASSIC]: classicStreamerStandardNavigationItems,
  [Role.INFLUENCER]: [
    ...helloStreamerAndInfluencerStandardNavigationItems,
    creatorProfileNavigationItem,
  ],
  [Role.AGENCY]: agencyNavigationItems,
  [Role.HSE_EMPLOYEE]: hseEmployeeNavigationItemsMobile,
};

export const getNavigationItemsForUserRoles = (
  navigationItemsSet: Record<Role, NavigationItem[]>
) =>
  createSelector(
    selectUserRoles,
    (userRoles: Role[] | undefined): NavigationItem[] | [] =>
      userRoles?.reduce(
        (acc, cur) => [...acc, ...navigationItemsSet[cur]],
        [] as NavigationItem[]
      ) ?? []
  );

function findNavigationItemIndexWhichMatchesCurrentLocation(
  availableNavigationItems: NavigationItem[],
  currentLocation: Location
) {
  return (availableNavigationItems ?? []).findIndex(navigationItem => {
    const allPathsOfNavigationItem = [
      navigationItem.path,
      ...(navigationItem.secondaryPaths ?? []),
    ];
    return allPathsOfNavigationItem.some(path => matchPath(currentLocation.pathname, { path }));
  });
}

export const selectActiveNavigationItem = (navigationItemsSet: Record<Role, NavigationItem[]>) =>
  createSelector(
    getNavigationItemsForUserRoles(navigationItemsSet),
    selectCurrentLocation,
    (availableNavigationItems, currentLocation) => {
      const navigationItemIndex = findNavigationItemIndexWhichMatchesCurrentLocation(
        availableNavigationItems,
        currentLocation
      );
      return navigationItemIndex !== -1 ? navigationItemIndex : false;
    }
  );

export const getNameOfActiveNavigationItemByCurrentLocation = (
  navigationItemsSet: Record<Role, NavigationItem[]>
) =>
  createSelector(
    getNavigationItemsForUserRoles(navigationItemsSet),
    selectCurrentLocation,
    (availableNavigationItems, currentLocation) => {
      const navigationItemIndex = findNavigationItemIndexWhichMatchesCurrentLocation(
        availableNavigationItems,
        currentLocation
      );
      return availableNavigationItems && availableNavigationItems[navigationItemIndex]
        ? availableNavigationItems[navigationItemIndex].name
        : '';
    }
  );
