import { ProductDimension, VariantDimension } from './dimensions';
import { ProductPrice } from './price';

export interface Product {
  baseProductNo: string;
  countryCode: ECountryCode;
  name: ProductName;
  description?: string;
  additionalInformation?: string;
  hazardWarning?: string;
  ingredients?: string;
  headline?: string;
  media?: Media[];
  usps?: string[];
  energyEfficiency?: IEnergyEfficiency;
  salesdrivers?: Salesdriver[];
  legalInformation?: Media;
  showStartingPrice?: boolean;
  defaultVariantSku: string;
  variants: Variant[];
  brand?: ProductBrand;
  status: BaseProductStatus;
  shipping?: {
    freeShipping?: boolean;
    activeGlobalCampaign?: boolean;
  };
  freeShippingCampaigns?: FreeShippingCampaign[];
  financing?: {
    zeroPercent: boolean;
  };
  seo?: ProductSeo;
  categoryPath?: string;
  created?: string;
  newInShop?: string;
  isNew?: boolean;
  countryVisibilities?: ECountryCode[];
  hideReturnInformation?: boolean;
  soldOnMedia?: SoldOnMediaValue[];
  dimensions?: ProductDimension[];
}

export interface ProductSeo {
  description?: string;
  title?: string;
}

export interface ProductName {
  short?: string;
  long: string;
}

export interface ProductBrand {
  brandName?: string;
  brandNameShort?: string;
  productLineName?: string;
  brandIcon?: Media;
}

export interface Media {
  uri: string;
  mediaType: 'image' | 'video' | 'document';
  height?: number;
  width?: number;
  model?: ModelInfo;
}

export interface ModelInfo {
  sizeCm?: string;
  dressSize?: string;
}

export interface IEnergyEfficiency {
  class?: string;
  colorCode?: string;
  label?: Media;
  infoSheet?: Media;
  icon?: Media;
}

export interface Salesdriver {
  typeCode?: string;
  name?: string;
  validFrom?: string;
  validTo?: string;
}

export interface DeliveryTime {
  deliveryMessage: string;
  shippingType?: ShippingType;
  deliveryDays?: number;
}

export interface Variant {
  sku: string;
  status?: ProductStatus;
  price: ProductPrice;
  deliveryTime?: DeliveryTime;
  images?: Media[];
  dimensions?: VariantDimension[];
}

export interface FreeShippingCampaign {
  campaignId?: string;
  voucherCode?: string;
  campaignType?: FreeShippingCampaignType;
  minOrderValue?: number;
}

export enum BaseProductStatus {
  Sellable = 'SELLABLE',
  Waitlistsellable = 'WAITLISTSELLABLE',
  Soldout = 'SOLDOUT',
  SubscriptionSellable = 'SUBSCRIPTION_SELLABLE',
  Archived = 'ARCHIVED',
  Discarded = 'DISCARDED',
  Unprepared = 'UNPREPARED',
  Prepared = 'PREPARED',
}

export enum ProductStatus {
  Sellable = 'SELLABLE',
  Soldout = 'SOLDOUT',
  SubscriptionSellable = 'SUBSCRIPTION_SELLABLE',
  Archived = 'ARCHIVED',
  Unprepared = 'UNPREPARED',
  Prepared = 'PREPARED',
  Discarded = 'DISCARDED',
}

export enum ECountryCode {
  DE = 'DE',
  AT = 'AT',
  CH = 'CH',
}

export enum FreeShippingCampaignType {
  PRODUCT = 'PRODUCT',
  ASSORTMENT = 'ASSORTMENT',
  GLOBAL = 'GLOBAL',
  PERIOD = 'PERIOD',
}

export enum ShippingType {
  dropShipment = 'DROP_SHIPMENT',
  dropShipmentWithTelephoneNotification = 'DROP_SHIPMENT_WITH_TELEPHONE_NOTIFICATION',
  standard = 'STANDARD',
  unknown = 'UNKNOWN',
}

export enum SoldOnMediaValue {
  socialLiveCommerce = 'SOCIAL_LIVE_COMMERCE',
  traditional = 'TRADITIONAL',
}
