export const usernames = [
  'Lukas',
  'Sven',
  'Maximilian',
  'Paul',
  'Felix',
  'Anna',
  'Laura',
  'Sophie',
  'Marie',
  'Johannes',
];
