import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import theme from '../../../../theme/theme.module.scss';
import ShowStressTestForm from '../../../component/ShowStressTestForm/ShowStressTestForm';
import { ShowDetails } from '../../../model/shows';
import { fetchShowDetails, selectShowDetailsData } from '../../../state/showsSlice';

const StressTestShowPage = () => {
  const dispatch = useDispatch();

  const { showId } = useParams<{ showId: string }>();

  useEffect(() => {
    dispatch(fetchShowDetails(showId));
  }, []);

  const showDetails: ShowDetails | undefined = useSelector(selectShowDetailsData);

  if (!showDetails) {
    return <p>showDetails not found</p>;
  }

  return (
    <PageContainer
      backgroundColor={theme.whiteLilac}
      pageToRender={
        <ShowStressTestForm
          showId={showDetails.id}
          showTitle={showDetails.title}
          streamerName={showDetails.creatorDetails.name}
        ></ShowStressTestForm>
      }
      fullHeight
    />
  );
};

export default StressTestShowPage;
