import { Button, EButtonSize, EButtonType, EInputTypes } from '@hse24/shared-components';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormHeader from '../../../components/FormHeader/FormHeader';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import t from '../../../constants/translation';
import { selectProductSelection } from '../../../products/state/productsSlice';
import useFormChanged from '../../../shows/utils/useFormChanged';
import { toBerlinTimeZone } from '../../../utils/dates';
import { revertHTMLFormatToWhiteSpaces } from '../../../utils/formatters/htmlFormatter/htmlFormatter';
import { StreamerPostResponse } from '../../api/postsRequestResponse';
import { ScheduledWhen, StreamPostData } from '../../model/post';
import { FileMetaData, selectSupportedSelectedFiles } from '../../state/postsSlice';
import { getFileExtension, getMediaType, getSupportedFileExtension } from '../../utils/MediaUtils';
import {
  immediateCreatePostSchema,
  plannedCreatePostSchema,
  postSchema,
} from '../../utils/PostSchema/PostSchema';
import DataEntryForm from '../AdminCreateEditPostForm/DataEntryForm/DataEntryForm';
import PostMediaPreviews from '../AdminCreateEditPostForm/PostMediaPreviews/PostMediaPreviews';
import MusicLicenseWarning from '../MusicLicenseWarning/MusicLicenseWarning';
import { UploadArea } from '../UploadArea/UploadArea';
import styles from './CreateEditPostForm.module.scss';

export interface PostData {
  caption?: string | null;
  baseProductsNo?: string[];
  scheduledStartAt?: string;
  scheduleAt?: Date;
  selectedFiles: string[];
}

interface CreateEditPostFormProps {
  isCreate?: boolean;
  isOwnPost?: boolean;
  postToUpdate?: StreamerPostResponse;
  submitCallback: (values: StreamPostData) => void;
}

const CreateEditPostForm: FC<CreateEditPostFormProps> = ({
  isCreate = false,
  submitCallback,
  isOwnPost,
  postToUpdate,
}: CreateEditPostFormProps) => {
  const history = useHistory();

  const { selectedProducts } = useSelector(selectProductSelection);
  const selectedFiles: FileMetaData[] = useSelector(selectSupportedSelectedFiles);

  const postCaption = useMemo(
    () => (postToUpdate?.caption ? revertHTMLFormatToWhiteSpaces(postToUpdate?.caption) : ''),
    [postToUpdate?.caption]
  );

  const [formValues, setFormValues] = useState<PostData>({
    caption: postCaption || '',
    scheduleAt: postToUpdate?.scheduleAt ? dayjs(postToUpdate.scheduleAt).toDate() : undefined,
    selectedFiles: selectedFiles.map(f => f.key),
  });

  const initialSelectedFiles = useMemo(() => selectedFiles.map(f => f.key), []);

  const [nowOrLater, setNowOrLater] = useState(
    formValues.scheduleAt ? ScheduledWhen.PLANNED : ScheduledWhen.IMMEDIATELY
  );

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const getScheduleAt = () => formValues.scheduleAt?.toISOString() || '';

  const hasFormChanged = useFormChanged<PostData>(
    {
      caption: formValues.caption,
      baseProductsNo: selectedProducts.map(p => p.baseProductNo),
      scheduledStartAt: getScheduleAt(),
      selectedFiles: selectedFiles.map(f => f.key),
    },
    postToUpdate
      ? {
          caption: postCaption,
          baseProductsNo: postToUpdate.products?.map(p => p.baseProductNo) || [],
          scheduledStartAt: dayjs(postToUpdate.scheduleAt).toDate().toISOString(),
          selectedFiles: initialSelectedFiles,
        }
      : null
  );

  const displayButton = !(!isOwnPost && !isCreate);

  const initialValues: StreamPostData & { productSearch: string } = {
    scheduleAt: postToUpdate?.scheduleAt ?? '',
    caption: postCaption ?? '',
    baseProductsNo: [], // Will be taken from the selector in the child component
    productSearch: '',
    medias: [],
    type: nowOrLater,
  };

  const mapFormValuesToSchedulePostData = (
    values: StreamPostData & { productSearch: string }
  ): StreamPostData => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { productSearch, ...rest } = values;
    rest.scheduleAt = formValues.scheduleAt
      ? toBerlinTimeZone(formValues.scheduleAt).toISOString()
      : toBerlinTimeZone(dayjs().toDate()).toISOString();
    rest.medias = selectedFiles.map(selectedFile => {
      return {
        ...selectedFile,
        mediaType: getMediaType(selectedFile.key),
        extension: getSupportedFileExtension(getFileExtension(selectedFile.key)),
        url: selectedFile.key,
      };
    });
    rest.type = nowOrLater;
    return rest;
  };

  const handleCancel = () => history.goBack();

  const setFormValue = (field: string, value?: string | Date) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const getSubmitButtonText = (): string => {
    if (isCreate) {
      return nowOrLater === ScheduledWhen.PLANNED
        ? t.creators.post['Planned publish']
        : t.creators.post['Publish'];
    }
    return t.creators.post['Edit'];
  };

  return (
    <>
      <FormHeader
        title={t.admins.post[isCreate ? 'Create Post' : 'Edit Post']}
        backButtonText={t.creators.Create}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={
          !isCreate
            ? postSchema
            : nowOrLater === ScheduledWhen.IMMEDIATELY
            ? immediateCreatePostSchema
            : plannedCreatePostSchema
        }
        onSubmit={values => {
          setIsFormSubmitted(true);
          submitCallback(mapFormValuesToSchedulePostData(values));
        }}
        initialTouched={{
          scheduleAt: false,
          caption: false,
          baseProductsNo: false,
        }}
        initialErrors={{ baseProductsNo: '' }}
      >
        {({ setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className={styles.wrapper} data-testid="classic-post-form">
                <Grid className={styles.container} container gap={3}>
                  <Grid container item xs={12} md={6} gap={3}>
                    <DataEntryForm
                      formValues={formValues}
                      setFormValue={setFormValue}
                      isSubmitClicked={isSubmitClicked}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      nowOrLater={nowOrLater}
                      setNowOrLater={setNowOrLater}
                      caption={postCaption}
                    />
                    {displayButton && (
                      <Grid container item xs={12} className={styles.desktop_actions}>
                        <Button
                          onClick={handleCancel}
                          type={EButtonType.BLANK}
                          inputType={EInputTypes.button}
                        >
                          <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                        </Button>
                        <Button
                          type={EButtonType.PRIMARY}
                          inputType={EInputTypes.submit}
                          onClick={() => {
                            setIsSubmitClicked(true);
                          }}
                          data-testid="submit-button"
                        >
                          {getSubmitButtonText()}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid className={styles.media_area} container item xs={12} md={6}>
                    <Grid item xs={12}>
                      <Box className={styles.image_selection}>
                        <UploadArea isUpdate={!isCreate} isSubmitClicked={isSubmitClicked} />
                      </Box>
                    </Grid>
                    <Grid className={styles.media_slider} item xs={12}>
                      <PostMediaPreviews />
                    </Grid>
                    <Grid className={styles.music_licence_area}>
                      <MusicLicenseWarning />
                    </Grid>
                  </Grid>
                  {displayButton && (
                    <Grid container item className={styles.actions} gap={2}>
                      <Button
                        type={EButtonType.BLANK}
                        onClick={handleCancel}
                        size={EButtonSize.MEDIUM}
                        inputType={EInputTypes.button}
                      >
                        <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                      </Button>
                      <Button
                        type={EButtonType.PRIMARY}
                        inputType={EInputTypes.submit}
                        size={EButtonSize.MEDIUM}
                        onClick={() => {
                          setIsSubmitClicked(true);
                        }}
                        data-testid="submit-button"
                      >
                        {t.creators.post[isCreate ? 'Publish' : 'Edit']}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>
      <RouteLeavingGuard blockNavigation={hasFormChanged} navigationPermitted={isFormSubmitted} />
    </>
  );
};

export default CreateEditPostForm;
