import { LiveTv, Share } from '@mui/icons-material';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import t from '../../../../src/constants/translation';
import StyledMenu from '../../../components/StyledMenu/StyledMenu';
import Config from '../../../config/config';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import { selectLoggedInCreator } from '../../../creators/state/creatorsSlice';
import routePaths from '../../../routes/routePaths';
import { sendMessageToNativeApp } from '../../../storage/dataExchangerService';
import { buildClickMessageEvent, ClickEventElement } from '../../../storage/nativeMessages';
import { UpcomingShow } from '../../model/shows';
import { deleteShow, getShowShorterLink } from '../../state/showsSlice';
import isAllowedToStartStream from '../../utils/isAllowedToStartStream';
import { notAllowedCreators, notAllowedToStreamAlert } from '../../utils/notAllowedCreators';
import styles from './UpcomingShowsTableMenu.module.scss';

interface Props {
  show: UpcomingShow;
  className?: string;
  iconClassname?: string;
  hideStartStream?: boolean;
}

function UpcomingShowsTableMenu({
  className,
  show,
  iconClassname,
  hideStartStream = false,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const openDeleteDialog = () => setDeleteDialogOpen(true);
  const closeDeleteDialog = () => setDeleteDialogOpen(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const isInApp = featureFlagValue(AvailableFlag.isInApp);
  const amasEnabled = featureFlagValue(AvailableFlag.AmasEnabled);

  const canStartStream = isAllowedToStartStream(show);

  const isStartStreamingButtonDisplayed = isInApp && canStartStream && !hideStartStream;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeTableMenu = () => {
    setAnchorEl(null);
  };

  const handleShowSelected = () => {
    history.push(routePaths.creator.updateShow.replace(':showId', show.id));
  };

  const handleShareLink = () => {
    closeTableMenu();
    return isInApp
      ? sendMessageToNativeApp(buildClickMessageEvent(ClickEventElement.SHARE_SHOW, show.id))
      : dispatch(getShowShorterLink(show.id));
  };

  const creator = useSelector(selectLoggedInCreator);

  const handleStartStreaming = () => {
    if (creator && notAllowedCreators.includes(creator.id)) {
      alert(notAllowedToStreamAlert);
    } else {
      sendMessageToNativeApp(buildClickMessageEvent(ClickEventElement.START_STREAMING, show.id));
    }
  };

  const handleDelete = () => {
    dispatch(deleteShow(show.id));
  };

  const handleAmaClick = () => {
    history.push(routePaths.creator.onDemandInteractions.ama.dashboard.replace(':showId', show.id));
  };

  const handleOpenChat = () => {
    isInApp
      ? history.push(routePaths.creator.streamChat.replace(':streamId', show.id))
      : window.open(
          Config.env.baseRoute.concat(routePaths.creator.streamChat.replace(':streamId', show.id)),
          '_blank'
        );
  };

  return (
    <>
      <IconButton
        className={className}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid={'open-menu'}
      >
        <MoreVertIcon className={iconClassname} />
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={closeTableMenu}>
        <MenuItem data-testid={'share-button'} onClick={handleShareLink} disableRipple>
          <Share fontSize={'small'} className={styles.item_icon} />
          Share
        </MenuItem>
        <MenuItem data-testid={'chat-button'} onClick={handleOpenChat} disableRipple>
          <ChatOutlinedIcon fontSize={'small'} className={styles.item_icon} />
          Öffne externen Chat
        </MenuItem>
        <MenuItem
          key={'edit-button-key'}
          data-testid={'edit-button'}
          onClick={handleShowSelected}
          disableRipple
        >
          <EditIcon className={styles.item_icon} fontSize={'small'} />
          Bearbeiten
        </MenuItem>
        {isStartStreamingButtonDisplayed && (
          <MenuItem
            key={'start-streaming-button-key'}
            data-testid={'start-streaming-button'}
            onClick={handleStartStreaming}
            className={styles.start_streaming_menu_item}
            disableRipple
          >
            <LiveTv className={styles.item_icon} fontSize={'small'} />
            Stream starten
          </MenuItem>
        )}
        {amasEnabled && (
          <MenuItem data-testid={'create-ama-button'} onClick={handleAmaClick} disableRipple>
            <QuestionAnswerIcon className={styles.item_icon} fontSize={'small'} />
            AMA
          </MenuItem>
        )}
        <MenuItem
          key={'delete-show-button-key'}
          data-testid={'delete-show-button'}
          onClick={openDeleteDialog}
          className={styles.delete_show_menu_item}
          disableRipple
        >
          <DeleteIcon className={styles.item_icon} fontSize={'small'} />
          Löschen
        </MenuItem>
      </StyledMenu>
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Stream löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchtest du den Stream &apos;{show.title}&apos; unwiderruflich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>{t.common.Cancel}</Button>
          <Button
            data-testid={'confirm-delete-show-button'}
            onClick={() => {
              handleDelete();
              closeDeleteDialog();
              closeTableMenu();
            }}
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpcomingShowsTableMenu;
