import { Box } from '@mui/material';
import { StreamChatMessage } from '../../state/streamChatSlice';
import ChatListItem from '../ChatListItem/ChatListItem';
import styles from './ChatList.module.scss';

interface ChatListProps {
  messages: StreamChatMessage[];
  fontSize: number;
}
export default function ChatList({ messages, fontSize }: ChatListProps) {
  return (
    <div className={styles.chat_log}>
      <Box style={{ height: fontSize * 3, width: '100%' }}></Box>
      {messages
        // Hide REACTION messages
        .filter(value => value.type == 'MESSAGE')
        .map((chatMessage, key) => (
          <ChatListItem key={key} chatMessage={chatMessage} fontSize={fontSize} />
        ))}
    </div>
  );
}
