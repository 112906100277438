import { Button, EButtonSize, EButtonType, EModalSize, Modal } from '@hse24/shared-components';
import { AddOutlined, ChevronLeft } from '@mui/icons-material';
import { Alert, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { Audience } from '../../../common/types';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import {
  addProductToShow,
  endShow,
  fetchShowDetails,
  selectShowDetailsData,
  selectShowDetailsLoading,
} from '../../state/showsSlice';
import HighlightProducts from '../HighlightProductsList/HighlightProducts';
import Loading from '../Loading/Loading';
import ShowProductSearch from '../ShowProductSearch/ShowProductSearch';
import styles from './ShowModeration.module.scss';
import { AddProductsSchema } from './ShowModeration.service';

const ShowModeration = () => {
  const { showId } = useParams<{ showId: string }>();
  const showDetails = useSelector(selectShowDetailsData);
  const loading = useSelector(selectShowDetailsLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEndShowDialogOpen, setIsEndShowDialogOpen] = useState(false);

  const { isError, message } = useSelector((state: RootState) => state.shows.highLightProductState);

  useEffect(() => {
    dispatch(fetchShowDetails(showId));
  }, []);

  useEffect(() => {
    if (showDetails?.endedAt) {
      const path = generatePath(routePaths.hseEmployee.showDetails, { showId: showDetails.id });
      history.push(path);
    }
  }, [showDetails?.endedAt, history]);

  if (loading) {
    return <Loading />;
  }

  const triggerEndShowRequest = () => {
    if (showDetails) {
      dispatch(endShow(showDetails));
      setIsEndShowDialogOpen(false);
    }
  };

  const submit = (values: { baseProductsNo: string[]; productSearch: string }) => {
    dispatch(addProductToShow({ showId, baseProductsNo: values.baseProductsNo }));
    setIsDialogOpen(false);
  };

  return (
    <>
      <div>
        <Stack direction="row" justifyContent="space-between">
          <Button
            className={styles.link_back}
            onClick={() => history.goBack()}
            type={EButtonType.NONE}
          >
            <ChevronLeft />
            Zurück
          </Button>

          {showDetails && (
            <Button className={styles.link_back} onClick={() => setIsEndShowDialogOpen(true)}>
              <Typography fontWeight={700}> {t.admins.showModeration.endStream}</Typography>
            </Button>
          )}
        </Stack>
      </div>
      {showDetails && (
        <div className={styles.show_details_container}>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <h1 className={styles.header}>{showDetails.title}</h1>
            </div>
            <div>
              <Button
                type={EButtonType.LINK}
                icon={{ left: <AddOutlined /> }}
                onClick={() => setIsDialogOpen(true)}
              >
                {t.admins.showModeration.addProduct}
              </Button>
            </div>
          </Stack>
          <Stack my={4}>{isError && <Alert severity="error"> {message} </Alert>}</Stack>
          <Stack alignItems="center" justifyContent="space-around">
            <HighlightProducts showId={showId} />
          </Stack>
        </div>
      )}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth={true}>
        <DialogContent>
          <Formik
            initialValues={{
              baseProductsNo: [] as string[],
              productSearch: '',
            }}
            onSubmit={submit}
            validationSchema={AddProductsSchema}
          >
            <Form>
              <ShowProductSearch audience={showDetails?.audience ?? [Audience.HELLO]} />
              <Button className={styles.product_popup_btn}>
                {t.admins.showModeration.addProducts}
              </Button>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
      <Modal
        showModal={isEndShowDialogOpen}
        onRequestClose={() => setIsEndShowDialogOpen(false)}
        size={EModalSize.SMALL}
        className={styles.end_show_dialog}
        closeIconClassName={styles.close_icon}
      >
        <div>
          <div className={styles.end_show_header}>{t.admins.showModeration.confirmEndStream}</div>
          <div className={styles.end_show_body}>{t.admins.showModeration.confirmEndStreamBody}</div>
          <div data-testid="end-show-dialog-actions" className={styles.button_group}>
            <Button
              size={EButtonSize.SMALL}
              type={EButtonType.BLANK}
              className={styles.cancel_button}
              onClick={() => setIsEndShowDialogOpen(false)}
            >
              {t.common.Cancel}
            </Button>
            <Button
              className={styles.confirm_button}
              size={EButtonSize.SMALL}
              onClick={triggerEndShowRequest}
            >
              {t.admins.showModeration.endStream}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowModeration;
