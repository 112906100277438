import { Text } from '@hse24/shared-components';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import StyledDialog from '../../../../components/StyledDialog/StyledDialog';
import t from '../../../../constants/translation';
import styles from './MusicLicenseWarningPopup.module.scss';

interface MusicLicenseWarningPopupProps {
  isMusicLicenseWarningPopupOpen: boolean;
  handleClose: (isOpen: boolean) => void;
}

const MusicLicenseWarningPopup = ({
  isMusicLicenseWarningPopupOpen,
  handleClose,
}: MusicLicenseWarningPopupProps) => {
  const close = () => {
    handleClose(false);
  };
  return (
    <StyledDialog
      close={close}
      dialogProps={{
        open: isMusicLicenseWarningPopupOpen,
        maxWidth: 'md',
        PaperProps: {
          className: styles.wrapper,
        },
      }}
      fullWidth={true}
      testId="music-license-warning-popup"
      classes={{
        header: styles.header,
        body: styles.componentBody,
        closeIcon: styles.closeButton,
      }}
      header={
        <>
          <div data-testid="music-license-warning-popup-header">
            <section
              className={styles.grabber}
              data-testid="music-license-warning-popup-grabber"
              onClick={close}
            />
            <div
              className={styles.headerContent}
              data-testid="music-license-warning-popup-header-title"
            >
              <Text.J1 className={styles.title}>{t.creators.post['Check copyright']}</Text.J1>
            </div>
            <div className={styles.divider} />
          </div>
        </>
      }
      body={
        <>
          <div className={styles.content} data-testid="music-license-warning-popup-body">
            <Icon
              icon={Icons.warningIcon}
              className={styles.warning_icon}
              dataTestId="music-licence-warning-icon"
            />
            <Text.Body className={styles.text}>
              {t.creators.post['Please only use music approved by HSE in your videos']}
            </Text.Body>
            <Text.BodyMicro
              className={styles.copyright_warning_text}
              data-testid="copyright-warning-text"
            >
              {t.creators.post['Copyright warning text headline']}
              <br />
              <span className={styles.afterBreak}>
                {t.creators.post['Copyright warning text detail']}
              </span>
            </Text.BodyMicro>
          </div>
        </>
      }
    />
  );
};

export default MusicLicenseWarningPopup;
