const paths = {
  test1: '/:showId/test',
  test2: '/test/:amaId',
  landing: '/',
  privacyPolicy: '/privacy-policy',
  termsConditions: '/terms-and-conditions',
  imprint: '/imprint',
  auth: '/auth',
  welcome: '/welcome',
  setPassword: '/set-password',
  minimalHorizontalFooter: true,

  creator: {
    dashboard: '/creator/dashboard',
    classicDashboard: '/creator/classic-dashboard',
    shows: '/creator/shows',
    upcomingShows: '/creator/upcoming-shows',
    addShow: '/creator/add-creator-show',
    contentCreation: '/creator/content-creation',
    posts: '/creator/posts',
    addPost: '/creator/add-post',
    updatePost: '/creator/post/:postId',
    payments: '/creator/payments',
    analytics: '/creator/analytics',
    insights: '/creator/insights',
    academy: {
      overview: '/creator/academy',
      video: '/creator/academy/:videoId',
    },
    products: '/creator/products',
    editProfile: '/creator/edit-profile',
    streamSettings: '/creator/stream-settings',
    profileDashboard: '/creator/profile-dashboard',
    performance: '/creator/performance',
    invoiceDetails: '/creator/invoice/:invoiceId',
    updateShow: '/creator/show/:showId',
    showDetails: '/creator/show-details/:showId',
    showAma: '/creator/show-ama/:showId',
    streamChat: '/creator/stream-chat/:streamId',
    onDemandInteractions: {
      ama: {
        dashboard: '/creator/show/:showId/ama',
        create: '/creator/show/:showId/ama/create',
        display: '/creator/show/:showId/ama/:amaId',
        update: '/creator/show/:showId/ama/:amaId/update',
      },
    },
  },

  agency: {
    creatorsOverview: '/agency/creators-overview',
    creatorDetails: '/agency/creator-details/:streamerId',
    pastShowsOverview: '/agency/past-shows',
  },

  hseEmployee: {
    agenciesOverview: '/hse-employee/agencies-overview',
    addAgency: '/hse-employee/add-agency',
    agencyDetails: '/hse-employee/agency-details/:agencyId',
    creatorDetails: '/hse-employee/creator-details/:streamerId',
    creatorsOverview: '/hse-employee/creators-overview',
    upcomingShowsOverview: '/hse-employee/upcoming-shows',
    pastShowsOverview: '/hse-employee/past-shows',
    showDetails: '/hse-employee/show-details/:showId',
    showModeration: '/hse-employee/show-moderation/:showId',
    createPost: '/hse-employee/create-post',
    postsOverview: '/hse-employee/posts-overview',
    surveysOverview: '/hse-employee/surveys-overview',
    updateStreamerPost: '/hse-employee/post-details/:postId',
    addShow: '/hse-employee/add-show',
    editShow: '/hse-employee/edit-show/:showId',
    duplicateShow: '/hse-employee/duplicate-show/:showId',
    stressTest: '/hse-employee/stress-test/:showId',
  },
};

export default paths;
