import { ETag, IconWithText, Text } from '@hse24/shared-components';
import { useState } from 'react';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import styles from './MusicLicenseWarning.module.scss';
import MusicLicenseWarningPopup from './MusicLicenseWarningPopup/MusicLicenseWarningPopup';

const MusicLicenseWarning = () => {
  const [isModalMusicLicenseWarningPopupOpen, setModalMusicLicenseWarningPopupOpen] =
    useState(false);

  const onButtonClick = () => {
    setModalMusicLicenseWarningPopupOpen(true);
  };
  return (
    <>
      <section className={styles.wrapper} data-testid="music-licence-warning">
        <Text.Body
          tag={ETag.DIV}
          className={styles.title}
          data-testid="music-licence-warning-title"
        >
          {t.creators.post.copyrightCheck}
        </Text.Body>
        <div className={styles.content} onClick={onButtonClick}>
          <IconWithText
            icon={
              <Icon
                icon={Icons.warningIcon}
                className={styles.warning_icon}
                dataTestId="music-licence-warning-icon"
              />
            }
          >
            <Text.Body tag={ETag.P} data-testid="music-licence-warning-description">
              {`${t.creators.post.copyrightCheckDescription} `}
              <span
                className={styles.instructions_popup}
                onClick={onButtonClick}
                data-testid="music-licence-warning-link"
              >
                Details
              </span>
            </Text.Body>
          </IconWithText>
        </div>
      </section>
      <MusicLicenseWarningPopup
        handleClose={setModalMusicLicenseWarningPopupOpen}
        isMusicLicenseWarningPopupOpen={isModalMusicLicenseWarningPopupOpen}
      />
    </>
  );
};

export default MusicLicenseWarning;
