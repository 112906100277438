import dayjs from 'dayjs';
import * as yup from 'yup';
import { featureFlagValue } from '../../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../../config/FeatureFlags';
import { CreatorImageType } from '../../../model/creator';
import {
  profilePersonalDataKeys,
  Salutation,
  StreamerPersonalDataUi,
} from '../ProfilePersonalData';

const vatIdRegex = /^(DE)[0-9]{9}$/i;
const invalidVatIdMessage = 'Ungültige USt.-IdNr.';

const newLineRegex = /\r\n|\r|\n/;
const taxIdRegex = /^[0-9]{11}$/;
const invalidTaxIdMessage = 'Ungültige Steueridentifikationsnummer';
const requirePersonalDataFields = () => featureFlagValue(AvailableFlag.isStreamerGDPRDataEnabled);

const streamerSchemaValidation: yup.SchemaOf<StreamerPersonalDataUi> = yup.object({
  profileImage: yup.object().shape({
    imageType: yup
      .mixed<CreatorImageType>()
      .oneOf(Object.values(CreatorImageType))
      .required('Pflichtfeld'),
    fileExtension: yup.string().required('Pflichtfeld'),
    imageUrl: yup.string().required('Pflichtfeld'),
  }),

  name: yup.string().nullable().required('Pflichtfeld').max(30, 'Maximal 30 Zeichen'),

  description: yup
    .string()
    .nullable()
    .max(70, 'Maximal 70 Zeichen')
    .test('invalid', 'Maximal 3 Zeilen', value => !!value && value.split(newLineRegex)?.length <= 3)
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  salutation: yup
    .mixed<Salutation>()
    .oneOf(Object.values(Salutation))
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  firstName: yup
    .string()
    .max(50, 'Maximal 50 Zeichen')
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  lastName: yup
    .string()
    .max(50, 'Maximal 50 Zeichen')
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  street: yup
    .string()
    .max(50, 'Maximal 50 Zeichen')
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  streetNumber: yup
    .string()
    .max(30, 'Maximal 30 Zeichen')
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  zipCode: yup
    .string()
    .max(30, 'Maximal 30 Zeichen')
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  city: yup
    .string()
    .max(50, 'Maximal 50 Zeichen')
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  phoneNumber: yup.string().nullable().max(30, 'Maximal 30 Zeichen'),

  email: yup
    .string()
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  birthdate: yup
    .string()
    .default(null)
    .nullable()
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema =>
        schema
          .required('Pflichtfeld')
          .test('invalid', 'Ungültiges Datum', value => dayjs(value!).isValid())
          .test('invalid', 'Ungültiges Datum', value => new Date(value!).getFullYear() >= 1900)
          .test(
            'Underage',
            'Mindestens 18 Jahre',
            value => dayjs().diff(dayjs(value!), 'years') >= 18
          ),
      otherwise: schema => schema.notRequired(),
    }),

  accountHolderName: yup
    .string()
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld'),
      otherwise: schema => schema.notRequired(),
    }),

  iban: yup
    .string()
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema =>
        schema.required('Pflichtfeld').when([profilePersonalDataKeys.bic], {
          // Validation is based on external payment service. If IBAN is not valid, BIC can not be determined
          // (see ValidatedIbanTextField component)
          is: (bic: string) => !bic,
          then: yup.string().test('invalidBic', 'Ungültige IBAN', () => false),
        }),
      otherwise: schema => schema.notRequired(),
    }),

  bic: yup.string(),

  // Keep isLiableToVat required as originally
  isLiableToVat: yup.boolean().defined().required('Pflichtfeld'),

  vatId: yup
    .string()
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema =>
        schema.when([profilePersonalDataKeys.isLiableToVat], {
          is: true,
          then: s => s.nullable().required('Pflichtfeld').matches(vatIdRegex, invalidVatIdMessage),
          otherwise: s =>
            // If not liable, let user skip or provide any string
            s.nullable().optional(),
        }),
      otherwise: schema =>
        // If flag is false, skip validations entirely
        schema.notRequired(),
    }),

  taxId: yup
    .string()
    .default('')
    .defined()
    .when([], {
      is: () => requirePersonalDataFields(),
      then: schema => schema.required('Pflichtfeld').matches(taxIdRegex, invalidTaxIdMessage),
      otherwise: schema => schema.notRequired(),
    }),

  instagram: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  tiktok: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  youtube: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  facebook: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  snapchat: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  pinterest: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
});

export default streamerSchemaValidation;
