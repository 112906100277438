import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import CreatorContentComponent from '../../components/CreatorContentComponent/CreatorContentComponent';
import styles from './CreatorContentManagementPage.module.scss';

const CreatorContentManagementPage = () => {
  const content = () => <CreatorContentComponent />;

  return (
    <PageContainer
      pageToRender={content()}
      backgroundColor={theme.whiteLilac}
      fullHeight
      spacing={0}
      className={styles.creator_content_managment_container}
    />
  );
};

export default CreatorContentManagementPage;
