import { LabelFlag, Text } from '@hse24/shared-components';
import { FC, useState } from 'react';
import { getVoucherValue } from '../../../../../utils/formatters/voucherFormatter/voucherFormatter';
import { CMSVoucherResponse } from '../../../../../vouchers/model/voucher';
import { getDynamicVoucherDescription } from '../../../../utils/voucherUtils';
import styles from './VoucherCampaignSlide.module.scss';
import VoucherLegalTextPopup from './VoucherLegalTextPopup/VoucherLegalTextPopup';

interface VoucherCampaignSlideProps {
  voucher: CMSVoucherResponse;
}

const VoucherCampaignSlide: FC<VoucherCampaignSlideProps> = ({ voucher }) => {
  const [isModalForViewVoucherDetailsOpen, setModalForViewVoucherDetailsOpen] = useState(false);

  const onButtonClick = () => {
    setModalForViewVoucherDetailsOpen(true);
  };
  return (
    <>
      <div className={styles.voucher} data-testid="voucher-slide" onClick={onButtonClick}>
        <section className={styles.leftPart} data-testid="voucher-slide-title">
          <LabelFlag className={styles.value} text={getVoucherValue(voucher)} />
          <LabelFlag className={styles.valueSmall} text={voucher.code} />
        </section>

        <section className={styles.rightPart} data-testid="voucher-slide-description">
          <Text.BodyMicro className={styles.description}>
            {getDynamicVoucherDescription(voucher)}{' '}
            <span className={styles.link} onClick={onButtonClick}>
              Details
            </span>
          </Text.BodyMicro>
        </section>
      </div>
      <VoucherLegalTextPopup
        voucher={voucher}
        handleClose={setModalForViewVoucherDetailsOpen}
        isVoucherLegalTextPopupOpen={isModalForViewVoucherDetailsOpen}
      />
    </>
  );
};

export default VoucherCampaignSlide;
