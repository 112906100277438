import { camelizeKeys, decamelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import Config from '../../config/config';
import { ChatMessagesResponse, ChatRoomResponse, ChatTokenResponse } from '../types';

const defaultBaseUrl = `${Config.env.connectServiceBaseUrl}`;

const streamChatClient = (baseUrl: string) => authClient.createClient(`${baseUrl}`);

const getChatMessagesLive = async (showId: string, baseUrl: string = defaultBaseUrl) => {
  const response = await RefreshTokenUtil.wrap(() =>
    streamChatClient(baseUrl).get<ChatMessagesResponse>(`/chat-messages/live`, {
      params: { show_id: showId },
    })
  );
  return camelizeKeys(response.data) as ChatMessagesResponse;
};
const deleteMessage = async (
  messageId: string,
  showId: string,
  baseUrl: string = defaultBaseUrl
) => {
  await RefreshTokenUtil.wrap(() =>
    streamChatClient(baseUrl).delete(`/chat-messages/${showId}/${messageId}`)
  );
};

const getChatRoom = async (show_id: string, baseUrl: string = defaultBaseUrl) => {
  const response = await RefreshTokenUtil.wrap(() =>
    streamChatClient(baseUrl).get<ChatRoomResponse>(`/chat-rooms`, {
      params: { show_id },
    })
  );
  return camelizeKeys(response.data) as ChatRoomResponse;
};

const getChatToken = async (roomArn: string, userId: string, baseUrl: string = defaultBaseUrl) => {
  const response = await fetch(`${baseUrl}/chat-tokens`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(decamelizeKeys({ roomArn, userId })),
    credentials: 'include',
    cache: 'no-cache',
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  return camelizeKeys(data) as unknown as ChatTokenResponse;
};

const streamChatApi = { getChatMessagesLive, getChatToken, getChatRoom, deleteMessage };
export default streamChatApi;
